import { useState, useEffect } from 'react';
import axiosClient from "../axios-client";

const Social = () => {
    const [decryptedGoogleKey, setDecryptedGoogleKey] = useState('');
    const [decryptedFacebookKey, setDecryptedFacebookKey] = useState('');

    function arrayText() {
        return {
            'A': 'A1$', 'B': 'B2@', 'C': 'C3#', 'D': 'D4&',
            'E': 'E5*', 'F': 'F6(', 'G': 'G7)', 'H': 'H8_',
            'I': 'I9+', 'J': 'J0-', 'K': 'K!=', 'L': 'L^~',
            'M': 'a2t', 'N': 'N[}', 'O': 'O;/', 'P': 'P:<',
            'Q': 'Q>,', 'R': 'R.?', 'S': 'S/@', 'T': 'T#[',
            'U': 'U]\\', 'V': 'V^(', 'W': 'W*)', 'X': 'X+}',
            'Y': 'Y|{', 'Z': 'Z]_',

            'a': 'a1$', 'b': 'b2@', 'c': 'c3#', 'd': 'd4&',
            'e': 'e5*', 'f': 'f6(', 'g': 'g7)', 'h': 'h8_',
            'i': 'i9+', 'j': 'j0-', 'k': 'k!=', 'l': 'l^~',
            'm': 'wx2', 'n': 'n[}', 'o': 'o;/', 'p': 'p:<',
            'q': 'q>,', 'r': 'r.?', 's': 's/@', 't': 't#[',
            'u': 'u]\\', 'v': 'v^(', 'w': 'w*)', 'x': 'x+}',
            'y': 'y|{', 'z': 'z]_',

            '0': '0a$', '1': '1b@', '2': '2c#', '3': '3d&',
            '4': '4e*', '5': '5f(', '6': '6g)', '7': '7h_',
            '8': '8i+', '9': '9j-',

            ' ': 'spc', '!': 'exm', '"': 'dqt', '#': 'hsh',
            '$': 'dln', '%': 'pct', '&': 'amp', '\'': 'sqt',
            '(': 'lft', ')': 'rgt', '*': 'ast', '+': 'pls',
            ',': 'com', '-': 'dsh', '.': 'dot', '/': 'slh',
            ':': 'col', ';': 'scn', '<': 'les', '=': 'equ',
            '>': 'mor', '?': 'que', '@': 'at', '[': 'lbr',
            '\\': 'bks', ']': 'rbr', '^': 'cir', '_': 'und',
            '`': 'bac', '{': 'lcu', '|': 'vrt', '}': 'rcu',
            '~': 'til'
        };;
    }

    function decryptText(text) {
        const reverseMapping = Object.fromEntries(Object.entries(arrayText()).map(([key, value]) => [value, key]));
        const segments = text.split('|'); // Split by delimiter

        let decryptedText = '';

        segments.forEach(segment => {
            if (reverseMapping[segment]) {
                decryptedText += reverseMapping[segment];
            } else {
                decryptedText += segment;
            }
        });

        return decryptedText;
    }

    const handleDecrypt = () => {

        axiosClient.get('socialApp')
            .then(res => {
                setDecryptedGoogleKey(decryptText(res.data.data.google));
                setDecryptedFacebookKey(decryptText(res.data.data.facebook));
            })
            .catch(error => console.error('Error fetching encrypted data:', error));
    };
    useEffect(() => {
        handleDecrypt();
    }, []);

        return{decryptedFacebookKey,decryptedGoogleKey}
};

export default Social;