import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchProjects} from "../../../Managment/CategoryProjects";
import axiosClient from "../../../axios-client";
import ApiController from "./ApiController";
import {translateText} from "../../TranslateComponent";
import {toast} from "react-toastify";

const Logic=()=>{
    const translations = useSelector((state) => state.Storetranslate.translations);
    const dispatch = useDispatch();
    const navScrollingRef = useRef(null);
    const {slug} = useParams();
    const [pagedata,setPageData]=useState('');
    const [countries, setCountries]=useState([]);
    const [selectedFileShow, setSelectedFileShow] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [type,setType]=useState('zakat');
    const [giftCheck,setGiftCheck]=useState(false);
    const [checkTextArea,setCheckTextArea]=useState(countries[0]?.textArea||false)
    const [checkFile,setCheckFile]=useState(countries[0]?.file||false)
    const [name , setName]= useState('');
    const [email , setEmail]= useState('');
    const [selectedCountry , setSelectedCountry]=useState('');
    const [selectedType , setSelectedType]=useState('');
    const [desc,setDesc]=useState('');
    const {data} = useSelector((state)=>state.StoreCategoryProjects);

    useEffect(() => {
        if(countries.length>0){
            setCheckTextArea(countries[0]?.textArea);
            setCheckFile(countries[0]?.file);
        }
    }, [countries]);

    useEffect(()=>{
        const slug =sessionStorage.getItem('fixedSlug');
        if(data.length<1 && slug){
            dispatch(fetchProjects(slug));
        }
    },[]);

    useEffect(()=>{
        axiosClient.get(`/fixed/projects/${slug}`)
            .then(res=>{
                setPageData(res.data.data);
                setCountries(res.data.data.countries);
            })
            .catch(error=>{
                console.log(error);
            })
    },[slug])

    const removeFile =()=>{
        setSelectedFileShow(null);
        setSelectedFile(null);
    }
    const handleFileChange = (file) => {
        if(file){
            console.log(file)
            setSelectedFileShow(URL.createObjectURL(file));
            setSelectedFile(file);
        }
    };

    const handelFetchCountryByType =async  (id)=>{
        const data = await ApiController.CountryByType(id);
        if (data) {
            setCountries(data);
        } else {
            toast.info(translateText("No data returned",translations))
            console.log("No data returned");
        }
    }

    return{
        pagedata,
        navScrollingRef,
        translations,
        countries,
        setCountries,
        selectedFileShow,
        setSelectedFileShow,
        selectedFile,
        setSelectedFile,
        handleFileChange,
        type,
        setType,
        giftCheck,
        setGiftCheck,
        checkTextArea,
        setCheckTextArea,
        checkFile,
        setCheckFile,
        removeFile,
        name,
        setName,
        email,
        setEmail,
        selectedCountry,
        setSelectedCountry,
        selectedType,
        setSelectedType,
        desc,
        setDesc,
        data,
        handelFetchCountryByType,
    }
}
export default Logic;