import React, { useEffect, useRef, useState } from "react";
import { getUrl } from "../../../../axios-client";
import { useSelector } from "react-redux";
import { translateText } from "../../../TranslateComponent";
import { Arrow, SaudiFlag } from "../../../../Images/svgs";
import { LazyLoadImage } from "react-lazy-load-image-component";

function PhoneInput({ value, onChange }) {
  const DataAll = useSelector((state) => state.StoreCountries.countries);
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );

  // Country Code for Phone ...Start...
  const countryWrapperRef = useRef(null);
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    num_code: "+966",
    image: `${getUrl()}/assets/flags/ar.svg`,
    id: null,
  });
  const toggleCountryDropdown = () => setIsOpenCountry(!isOpenCountry);
  const [countries, setCountries] = useState([]);
  const [searchQueryCountry, setSearchQueryCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const handleCountrySearch = (e) => {
    const query = e.target.value;
    setSearchQueryCountry(query);
    const filtered = countries.filter((country) =>
      country.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCountries(filtered);
  };
  const handleCountrySelection = async (country) => {
    setSelectedCountry(country);
    setIsOpenCountry(false);
    setSearchQueryCountry("");
    setFilteredCountries(countries);
  };
  const ClientData = useSelector((state) => state.StoreVisits.data);
  const getCountries = async () => {
    try {
      const data = DataAll;
      setCountries(data);
      setFilteredCountries(data);
      const userCountry = data.filter(
        (item, index) => item.iso === ClientData.country
      );
      setSelectedCountry(
        userCountry[0] ||
          data[0] || {
            name: "No countries",
            num_code: "+966",
            image: `${getUrl()}/assets/flags/ar.svg`,
            id: null,
          }
      );
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    getCountries();
  }, [DataAll, ClientData]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        countryWrapperRef.current &&
        !countryWrapperRef.current.contains(event.target)
      ) {
        setIsOpenCountry(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  // Country Code for Phone ...Finish...

  return (
    <div className="flex flex-col pt-1 mt-1.5 w-full">
      <div className={"flex justify-center"}>
        <div className={" w-full"}>
          <div
            className={
              "w-full  px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 shadow-sm text-sm text-black flex"
            }
          >
            <input
              type="number"
              className=" p-2 w-full"
              placeholder={translateText("رقم الهاتف", translations)}
              maxLength={15}
              name="phoneNumber"
              value={value}
              onChange={onChange}
            />
            <div className="relative w-[100%]" ref={countryWrapperRef}>
              <div
                className="flex gap-2 justify-end text-sm font-medium leading-6  bg-white   text-neutral-900 cursor-pointer"
                onClick={toggleCountryDropdown}
              >
                <Arrow className={"pt-2"} />
                <span className="font-bold text-black pt-1">
                  {selectedCountry.num_code}
                </span>

                <LazyLoadImage
                  loading="lazy"
                  src={selectedCountry ? selectedCountry.image : <SaudiFlag />}
                  className="shrink-0 self-stretch aspect-square w-[30px] mask mask-circle object-cover"
                  alt="profile"
                />
              </div>
              {isOpenCountry && (
                <div className="absolute mt-1 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full overflow-y-scroll overflow-x-hidden">
                  <input
                    type="text"
                    value={searchQueryCountry}
                    onChange={handleCountrySearch}
                    placeholder={translateText('Search Country...',translations)}
                    className="w-full p-2 border-b focus:outline-none"
                  />
                  {filteredCountries.map((country) => (
                    <div
                      key={country.id}
                      className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleCountrySelection(country)}
                    >
                      <LazyLoadImage
                        src={country.image}
                        alt={country.name}
                        className="w-5 aspect-square mr-2 rounded-lg"
                      />
                      <span className="font-bold text-black">
                        {country.name}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneInput;
