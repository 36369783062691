import {Arrow} from "../../Images/svgs";
import  React from "react";
import {useEffect, useRef, useState} from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {useSelector} from "react-redux";
import TranslateComponent, { translateText } from "../TranslateComponent";


const Country = ({selectedCountryChose,setSelectedCountryChose}) =>{
    const DataAll =useSelector(state=>state.StoreCountries.countries)
    const translations = useSelector((state) => state.Storetranslate.translations);
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);

    const getCountries = async () => {
        try {
            const data = DataAll;
            setCountries(data);
            setFilteredCountries(data);
      } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };
    const [isOpenCountryChose, setIsOpenCountryChose] = useState(false);
    const [searchQueryCountry, setSearchQueryCountry] = useState('');
    const countryChoseWrapperRef = useRef(null);

    const handleCountrySearch = (e) => {
        const query = e.target.value;
        setSearchQueryCountry(query);
        const filtered = countries.filter(country => country.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredCountries(filtered);
    };
    const toggleCountryChoseDropdown = () => setIsOpenCountryChose(!isOpenCountryChose);

    useEffect(() => {
        getCountries();

    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {

            if (countryChoseWrapperRef.current && !countryChoseWrapperRef.current.contains(event.target)) {
                setIsOpenCountryChose(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


    const handleCountrySelectionChose = async (country) => {
        setSelectedCountryChose(country);
        setIsOpenCountryChose(false);
        setSearchQueryCountry('');
        setFilteredCountries(countries);
    };

    return (
        <div className="flex-1 max-md:max-w-full">
            <div className="relative w-[100%]" ref={countryChoseWrapperRef}>
                <div
                    className="flex gap-2  text-sm font-medium leading-6 text-right bg-white   text-neutral-900 cursor-pointer"
                    onClick={toggleCountryChoseDropdown}>
                    {selectedCountryChose.image ? (
                        <LazyLoadImage
                            loading="lazy"
                            src={selectedCountryChose.image}
                            className="shrink-0 self-stretch aspect-square w-[30px] mask mask-circle object-cover"
                            alt={selectedCountryChose.name}
                        />
                    ) : ('')}

                    <span className='font-bold text-black pt-1'>{selectedCountryChose.name}</span>

                    <Arrow className={"pt-2"}/>

                </div>
                {isOpenCountryChose && (
                    <div
                        className="absolute mt-1 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full overflow-y-scroll overflow-x-hidden">
                        <input
                            type="text"
                            value={searchQueryCountry}
                            onChange={handleCountrySearch}
                            placeholder={translateText('Search Country', translations)}
                            className="w-full p-2 border-b focus:outline-none"
                        />
                        {filteredCountries.map((country) => (
                            <div key={country.id}
                                 className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                                 onClick={() => handleCountrySelectionChose(country)}
                            >
                                <LazyLoadImage src={country.image} alt={country.name}
                                     className="w-5 aspect-square mr-2 rounded-lg"/>
                                <span className='font-bold text-black'>{country.name}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </div>



    )
}
export default Country;