import axiosClient from "../../../axios-client";

export default class ApiController {
    static async CountryByType(id){
        try {
            const response = await  axiosClient.get(`/fixed/country/projects/${id}`);
            return response.data.data
        }
        catch (e){
            console.log(e)
        }
    }

}