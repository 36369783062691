import { useEffect } from "react";

const NavScroll = ({ headerRef }) => {
  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const targetDiv = document.getElementById("target-dev");

        if (targetDiv) {
          const targetDivPosition = targetDiv.getBoundingClientRect().top;

          if (targetDivPosition <= 225) {
            headerRef.current.classList.add("stickyNav_hidden");
          } else {
            headerRef.current.classList.remove("stickyNav_hidden");
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerRef]);

  return null;
};

export default NavScroll;
