import React from 'react';
import TranslateComponent from "../../../TranslateComponent";

function DonationType({
  type,
  setType
}) {
    return (
        <div className="flex flex-col mt-6 w-full">
            <label className="self-start text-base font-medium leading-none text-slate-700">
               <TranslateComponent word={'صدقة'}/>
            </label>
            <div className="flex gap-2.5 items-start mt-4 w-full text-lg font-bold text-start whitespace-nowrap min-h-[40px]">
                <button onClick={()=>setType('zakat')} className={`flex-1  gap-2.5 self-stretch px-4 py-2  rounded-lg ${type==='zakat' && 'bg-blue-900 text-white'} border-blue-900 border-solid border-[0.8px] min-h-[40px] text-neutral-900`}>
                    <TranslateComponent word={'zakat'}/>
                </button>
                <button onClick={()=>setType('sadaqa')} className={`flex-1  gap-2.5 self-stretch px-4 py-2 rounded-lg  ${type==='sadaqa' && 'bg-blue-900 text-white'} border-blue-900  border-solid border-[0.8px] min-h-[40px]`}>
                    <TranslateComponent word={'charity'}/>
                </button>
            </div>
        </div>
    );
}

export default DonationType;