import {createSlice} from "@reduxjs/toolkit";

const CrumpRedux = createSlice({
    name:'CrupmStore',
    initialState:{
        data:[]
    },
    reducers:{
        setCrumb:(state,action)=>{
            state.data=action.payload
        }
    }
})

export const{setCrumb}= CrumpRedux.actions;

export default CrumpRedux.reducer;