import { configureStore } from '@reduxjs/toolkit';
import Slice from './Slice';
import Lang from "./Lang";
import Page from "./Page";
import Currencies from "./Currencies";
import WishList from "./WishList";
import Donation from "./Donation";
import Cart from "../Components/Cart/Cart";
import SerchResult from "./SerchResult";
import Countries from "./Countires";
import Blog from "./Blog";
import Projects from "./Projects";
import ZakatTable from './ZakatTable';
import Orphan from './Orphan';
import CancelSub from "./CancelSub";
import Translate from "./Translate";
import Header from './Header';
import Footer from './Footer';
import LoaderPageDownload from './LoaderPageDownload';
import ByCategory from "./ByCategory";
import Campaigns from "./Campaigns";
import Visits from "./ClientData";
import PayCards from "./PayCards";
import CrumpRedux from "./CrumpRedux"
import CategoryProjects from "./CategoryProjects"


const store = configureStore({
  reducer: {
    dropdown: Slice,
    Storelang: Lang,
    StorePage: Page,
    StoreCurrency :Currencies,
    StoreWishList:WishList,
    StoreDonation:Donation,
    StoreCart:Cart,
    StoreSerch:SerchResult,
    StoreCountries:Countries,
    StoreBlog:Blog,
    StoreProjects:Projects,
    StoreZakatTable:ZakatTable,
    StoreOrphan:Orphan,
    StoreCancelSub:CancelSub,
    StoreHeader:Header,
    StoreFooter:Footer,
    StoreLoaderPageDownload:LoaderPageDownload,
    Storetranslate: Translate,
    StoreByCategory:ByCategory,
    StoreCampaign:Campaigns,
    StoreVisits:Visits,
    StorePayCards:PayCards,
    CrupmStore:CrumpRedux,
    StoreCategoryProjects:CategoryProjects
  },
});
export default store;