
import React from 'react';
import { useSelector } from 'react-redux';
import { translateur } from '../Managment/Translate';


export const translateText = (word, translations) => {
    const translate = translateur;
    return word ? translate(word)(translations) : '';
};

const TranslateComponent = ({ word }) => {
    const translations = useSelector((state) => state.Storetranslate.translations);

    if (!translations || Object.keys(translations).length === 0) {
        const width = word && word.length * 8;
        return (
            <div className="inline-block bg-gray-200 animate-pulse" style={{ width: `${width}px`, height: '1rem' }}></div>
        );
    }

    const translatedWord = translateText(word, translations);
    return <>{translatedWord}</>;
};

export default TranslateComponent;
