import React, {useEffect, useState} from "react";

function ProjectInfo({description,className}) {
    const [dir, setDir] = useState('');

    useEffect(() => {
        const direction = document.documentElement.getAttribute('dir');
        if (direction) {
            setDir(direction);
        }
    }, [dir]);
    return (
        <article className={`${className} flex lg:relative ${dir === 'ltr' ? 'lg:pl-[8rem]' : 'lg:pr-[0rem]'} max-md:ml-0 max-md:w-full`}>
            <div className="flex flex-col items-start justify-start text-start self-stretch w-full max-md:mt-10 max-md:max-w-full mt-0">
                <div dangerouslySetInnerHTML={{__html: description}}/>
            </div>
        </article>
    );
}

export default ProjectInfo;