import { Info, X } from "lucide-react";
import React, { useState, useEffect } from "react";
import TranslateComponent from "./TranslateComponent";
import axiosClient from "axios-client";

const PopupFAQ = () => {
  const [faqs, setFaqs] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchMethods = () => {
      axiosClient
        .get("/qusPayment")
        .then((response) => {
          setFaqs(response.data.data);
          console.log(response);
        })
        .catch((error) => {
          console.log("log" + error);
        });
    };
    fetchMethods();
  }, []);

  const faqsFixed = [
    {
      question: "What is the purpose of this platform?",
      answer:
        "This platform aims to help people by providing donations for those in need.",
    },
    {
      question: "How can I donate?",
      answer:
        "You can donate by clicking on the donation button and selecting your desired amount.",
    },
    {
      question: "Is my donation secure?",
      answer:
        "Yes, all donations are processed securely through our payment gateways.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      {faqs && faqs.length > 0 && (
        <div className="h-[20px]">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-black hover:text-gray-900 focus:outline-none"
          >
            <Info size={20} />
          </button>

          {isOpen && (
            <div className="absolute right-[5%] top-[80px] bg-white shadow-lg p-4 rounded-md w-max z-[9999] text-white border">
              <h3 className="text-lg font-bold mb-4 text-center">
                <TranslateComponent word={"Frequently Asked Questions"} />
              </h3>
              {faqs.map((faq, index) => (
                <div key={index} className="mb-2">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="flex gap-x-2 justify-between items-center w-[340px] text-left py-2 px-3 bg-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none"
                  >
                    <span className="font-semibold">{faq.name}</span>
                    <span>{activeIndex === index ? "-" : "+"}</span>
                  </button>
                  {activeIndex === index && (
                    <div
                      className="p-3 bg-blue-50 rounded-b-lg text-gray-700 w-[340px]"
                      dangerouslySetInnerHTML={{ __html: faq.description }}
                    ></div>
                  )}
                </div>
              ))}

              {/* Close button */}
              <button
                onClick={() => setIsOpen(false)}
                className="top-[9px] right-4 p-2 text-gray-500 hover:text-black rounded-full absolute"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PopupFAQ;
