import {createSlice} from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const ClientData = createSlice({
    name:'StoreVisits',
    initialState:{
        data:''
    },
    reducers:{
        setData:(state,action)=>{
            state.data = action.payload;
        }
    }
})
export const {setData} = ClientData.actions;
export const fetchUserData = async (dispatch) => {
    try {
        const response = await axiosClient.get('https://api.country.is');
        dispatch(setData(response.data));
    } catch (err) {
        console.log(err);
    }
};

export default ClientData.reducer;