import {createSlice} from "@reduxjs/toolkit";
import axiosClient, {getCurrencyLocal, getLocalLang} from "../axios-client";

const ByCategory = createSlice({
    name:"StoreByCategory",
    initialState:{
        data:[],
        pagination:'',
        type:'',
        loader :false,
    },
    reducers:{
        setData:(state,action)=>{
            state.data = action.payload;
        },
        setPagination:(state,action)=>{
            state.pagination = action.payload;
        },
        setType:(state,action)=>{
            state.type = action.payload;
        },
        setLoader:(state,action)=>{
            state.loader = action.payload;
        }
    }
})

export const {setData,setPagination,setType,setLoader}=ByCategory.actions;

export const fetchGetProjects= (categorySlug=null,type=null ,page=1,limit)  => async (dispatch,getState)=>{
     await axiosClient('getByCategory',{
        params:{
            limit:limit??4,
            page:page,
            categoryId:categorySlug,
            type:type
        }
    }).then(res=>{
         const currentState = getState().StoreByCategory.data;
         dispatch(setData([...currentState,...res.data.data.data.data]));
         dispatch(setPagination(res.data.data.data.pagination));
         dispatch(setType(res.data.data.type));
         dispatch(setLoader(false));
    })
    .catch(error=>{
        console.log(error)
    })
}

export const fetchFilterForByCategory = (categorySlug=null, type=null ,  page=1,sort=null ,search=null) => async(dispatch,getState) =>{
     await axiosClient.get('/search/post',{
         params:{
             categoryId:categorySlug,
             type:type,
             limit:4,
             page:page,
             sort:sort,
             search:search
         }
    })
        .then(res=>{
            const currentState = getState().StoreByCategory.data;
            dispatch(setData([...currentState,...res.data.data.data.data]));
            dispatch(setPagination(res.data.data.data.pagination));
            dispatch(setType(res.data.data.type));
            dispatch(setLoader(false));
        })
        .catch(error=>{
            console.log(error)
        })
}

export const feacthFilterByCategory =(price,Country,Categories)=> async (dispatch,getState)=>{
    dispatch(setLoader(true));
    axiosClient.get('projects', {
        params: {
            lang:getLocalLang() && getLocalLang().code,
            limit: 4,
            page: null,
            currency: getCurrencyLocal()&&getCurrencyLocal().id,
            price: JSON.stringify(price),
            country:Country.id,
            category:JSON.stringify(Categories)
        }
    })
        .then(res => {
            dispatch(setData(res.data.data.data));
            dispatch(setLoader(false));

        })
        .catch(error => {
            dispatch(setLoader(false));
            console.error('Error:', error);
        });
}

export default ByCategory.reducer;