import React from "react";
import TranslateComponent from "Components/TranslateComponent";

function DonateButton({ price, currency, onClick, addCart }) {
  return (
    <div className="flex gap-3 self-end mt-6 w-full min-h-[36px]">
      <button
        onClick={addCart}
        className="flex gap-2 justify-center items-center px-3.5 py-2 h-[36px] bg-white rounded-lg border border-gray-300 border-solid shadow-sm w-[49px]"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4dc734fe9a9bca3cf87b26a252a8dc0b868c072266236daa85f30c858983ee9f?placeholderIfAbsent=true&apiKey=0ea99ad6805e4b00a629c3d17bcd7717"
          alt=""
          className="object-contain self-stretch my-auto w-[21px]"
        />
      </button>
      <button
        onClick={onClick}
        className="flex-1 shrink gap-2 self-start px-3.5 h-[36px] text-sm font-medium leading-none bg-amber-300 rounded-lg shadow-sm text-neutral-900"
      >
        <TranslateComponent word={"Donate now"} /> ({price} {currency})
      </button>
    </div>
  );
}

export default DonateButton;
