import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {getLocalLang} from "./axios-client";

const CustomLink = ({ to, ...props }) => {

    const lang = getLocalLang()?.code;
    return <RouterLink to={`/${lang}${to}`} {...props} />;
};

export default CustomLink;