import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const CACHE_KEY = 'header_cache';
const CACHE_TIMESTAMP_KEY = 'header_cache_timestamp';
const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000;
const Header=createSlice({
  name:'StoreHeader',
  initialState:{
    Headers:[],
  },
  reducers:{
    updateHeaderData:(state,action)=>{
      state.Headers=action.payload;
    }
  }
})
export const {updateHeaderData}=Header.actions;

export const fetchHeaders = () => async (dispatch) => {
    const now = Date.now();

    const cacheTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
    const cachedDataString = localStorage.getItem(CACHE_KEY);
    const cachedData = JSON.parse(cachedDataString)||[];
    if (cachedData.length>0 && cacheTimestamp && (now - cacheTimestamp < CACHE_DURATION)) {
        console.log('Using cached header data');
        dispatch(updateHeaderData(cachedData));
        return;
    }
    try {
        const response = await axiosClient.get(`/header`);
        const headersData = response.data.data;

        localStorage.setItem(CACHE_KEY, JSON.stringify(headersData));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, now.toString());

        dispatch(updateHeaderData(headersData));
    } catch (error) {
        console.error('Error:', error);
    }
};
export default Header.reducer;