import React, { useState } from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import TranslateComponent from "../TranslateComponent";
import {MinIcon, PlusIcon} from "../../Images/svgs";
import { getCurrencyLocal } from "axios-client";

const PopUpDate = ({
   type,
   setState,
   state,
   setSelectedMonthCount,
   selectedMonthCount,
   setStartDate,
   StartDate,
   price
}) => {
    const MonthNumbers = 12;
    const MonthArray = Array.from({ length: MonthNumbers }, (_, i) => i + 1);
    const DaysArray = Array.from({ length: 28 }, (_, i) => i + 1);
    const [ForMothnNmber, setForMothnNmber] = useState(true);
    const [quantity, setQuantity] = useState(2);

    const updateQuantity = (value) => {
        setQuantity(prevQuantity => {
            const newQuantity = prevQuantity + value;
            if (newQuantity >= 2  ) {
                setSelectedMonthCount(newQuantity);
                return newQuantity;
            }
            return prevQuantity;
        });
    };

    const [quantityYear, setQuantityYear] = useState(1);

    const updateQuantityYear = (value) => {
        setQuantityYear(prevQuantity => {
            const newQuantity = prevQuantity + value;
            if (newQuantity >= 1  ) {
                setSelectedMonthCount(newQuantity);
                setStartDate(newQuantity)
                return newQuantity;
            }
            return prevQuantity;
        });
    };

    return (
        <div className='flex flex-col items-center mb-6'>
            <div className="grid grid-cols-1">
                <div className="lg:col-span-1" dir={`${type === 'daily' ? 'ltr' : ''}`}>
                    {type === 'daily' && (
                        <DateRange
                            minDate={new Date()}
                            editableDateInputs={true}
                            onChange={item => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                        />
                    )}
                    {type === 'monthly' && (
                        <div className="grid grid-cols-1  gap-4">
                            <div className="lg:col-span-1 mt-auto">
                                <label className="my-2 sm:ml-4 mx-4">
                                    <TranslateComponent word={'Choose a start date'} />
                                </label>
                                <select
                                    className="select select-bordered !w-[165px]  max-w-xs"
                                    value={StartDate}
                                    onChange={e => setStartDate(e.target.value)}
                                >
                                    <option>
                                        <TranslateComponent word={'Choose a start date'} />
                                    </option>
                                    {DaysArray.map(day => (
                                        <option key={day} value={day} className="bg-white hover:bg-gray-100 rounded-md transition duration-300">
                                            <div>
                                                <div>
                                                    {day}
                                                    <TranslateComponent word={'From every month'} />
                                                </div>
                                            </div>
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-span-1">
                                <div className="flex flex-col items-center  justify-center">

                                    <div className="flex flex-row">
                                    <label
                                    className="inline-block px-[0.15rem] hover:cursor-pointer my-2"
                                    htmlFor="flexSwitchCheckedSms"
                                    >
                                    <TranslateComponent word={'Choose the number of months'} />
                                    </label>
                                    {/* <input
                                    type="checkbox"
                                    checked={ForMothnNmber}
                                    onChange={(e) => {
                                        setForMothnNmber(e.target.checked);
                                        setSelectedMonthCount('');
                                    }}
                                    className="checkbox checkbox-primary mx-2"
                                    id="flexSwitchCheckedSms"
                                    /> */}
                                    </div>
                                    {!ForMothnNmber && (
                                    <div className="text-red-500 text-sm mt-1">
                                    <TranslateComponent word={'Please select the number of months first'} />
                                    </div>
                                    )}
                                   
                                </div>

                                

                                <div className="text-center mt-1">
                                    <button
                                    type="button"
                                    onClick={() => updateQuantity(1)}
                                    className={`bg-blue-700 dark:bg-blue-700 font-medium rounded-[5px] text-sm px-2 py-2.5 hover:bg-sky-500 hover:ring-sky-500 ${!ForMothnNmber ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={!ForMothnNmber}
                                    >
                                    <PlusIcon />
                                    </button>
                                    <input
                                    type="number"
                                    value={quantity}
                                    onChange={() => {}}
                                    min="1"
                                    max="9"
                                    disabled
                                    className="border-0 bg-transparent text-center rounded-lg w-10 p-1"
                                    />
                                    <button
                                    type="button"
                                    onClick={() => updateQuantity(-1)}
                                    className={`bg-[#e6ebf8] dark:bg-[#e6ebf8] font-medium rounded-[5px] text-sm px-2 py-2.5 hover:bg-sky-500 hover:ring-sky-500 ${!ForMothnNmber ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={!ForMothnNmber}
                                    >
                                    <MinIcon />
                                    </button>
                                    <h5 className="mt-3 font-bold">
                                    <TranslateComponent word={'Every Month Will Donate'} /> {price} {getCurrencyLocal().code}
                                    </h5>
                                </div>

                             </div>
                        </div>
                    )}
                    {type === 'yearly' && (
                        <div>
                            <div className="flex flex-col items-center  justify-center">
                                <div className="flex flex-row items-center">
                                    <label
                                        className="inline-block pl-[0.15rem] hover:cursor-pointer my-2"
                                        htmlFor="flexSwitchCheckedSms"
                                    >
                                        <TranslateComponent word={'Choose the number of year'}/>
                                    </label>

                                </div>
                            </div>


                            <div className="text-center mt-1">
                                <button
                                    type="button"
                                    onClick={() => updateQuantityYear(1)}
                                    className={`bg-blue-700 dark:bg-blue-700 font-medium rounded-[5px] text-sm px-2 py-2.5 hover:bg-sky-500 hover:ring-sky-500 `}
                                >
                                    <PlusIcon/>
                                </button>
                                <input
                                    type="number"
                                    value={quantityYear}
                                    onChange={() => {
                                    }}
                                    min="1"
                                    max="9"
                                    disabled
                                    className="border-0 bg-transparent text-center rounded-lg w-10 p-1"
                                />
                                <button
                                    type="button"
                                    onClick={() => updateQuantityYear(-1)}
                                    className={`bg-[#e6ebf8] dark:bg-[#e6ebf8] font-medium rounded-[5px] text-sm px-2 py-2.5 hover:bg-sky-500 hover:ring-sky-500 `}
                                >
                                    <MinIcon/>
                                </button>
                            </div>

                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default PopUpDate;
