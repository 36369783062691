import axios from "axios";

let hasLang = true;

// export const fetchHasLangConfig = async () => {
//   try {
//     const response = await axios.get("https://hasLangAlkhairEn.vercel.app/api");
//     hasLang = response.data.hasLang;
//     return hasLang;
//   } catch () {
//     hasLang = false;
//     return false;
//   }
// };

// // Initialize hasLang configuration
// (async () => {
//   try {
//     await fetchHasLangConfig();
//   } catch () {
//   }
// })();

const axiosClient = axios.create({
  baseURL: "https://alk.hair/api",
});

export const getUrl = () => {
  return "https://alk.hair/api";
};

export const getUrlForDashboard = () => {
  return "https://alk.hair/ah-admin/";
};

export const getCatrs = () => {
  const carts = localStorage.getItem("carts");
  const CartsData = JSON.parse(carts);
  return CartsData;
};

export const getProjects = async () => {
  try {
    const response = await axiosClient.get("projects");
    return response.data.data.data;
  } catch (error) {
    console.error("Failed to fetch projects", error);
    return [];
  }
};

export const getCurrentMonth = () => {
  const date = new Date();
  const month = date.toLocaleString(
    getLocalLang() ? getLocalLang().code : "default",
    { month: "long" }
  );
  return month;
};

export const getNextMonth = () => {
  const currentDate = new Date();
  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );
  const month = nextMonthDate.toLocaleString(
    getLocalLang() ? getLocalLang().code : "default",
    { month: "long" }
  );
  return month;
};

export const getDaysInCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  const nextMonth = new Date(year, month + 1, 0);
  return nextMonth.getDate();
};

export const getNextMonthDayOfMonth = (currentDayOfMonth) => {
  const today = new Date();
  const nextMonth = new Date(today);
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  nextMonth.setDate(currentDayOfMonth);
  const dayOfMonthNextMonth = nextMonth.getDate();
  return dayOfMonthNextMonth;
};

export const formatDate = (date) => {
  if (!date) return "تاريخ غير صالح";
  const d = new Date(date);
  if (isNaN(d)) return "تاريخ غير صالح";
  const day = `0${d.getDate()}`.slice(-2);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};

export const truncateText = (text, length) => {
  if (!text) {
    return "";
  }

  if (text.length <= length) {
    return text;
  }

  const nextSpace = text.indexOf(" ", length);

  if (nextSpace === -1) {
    return text.substring(0, length) + "...";
  }

  return text.substring(0, nextSpace) + "...";
};


export const stripHtml = (html) => {
  let temporaryElement = document.createElement("div");
  temporaryElement.innerHTML = html;
  return temporaryElement.textContent || temporaryElement.innerText || "";
};

export const getToken = () => {
  const token = localStorage.getItem("AccessToken");
  return JSON.parse(token);
};

export const getCurrencyLocal = () => {
  const currency = localStorage.getItem("currency");
  return JSON.parse(currency);
};

export const saveCurrencyLocal = (currency) => {
  localStorage.setItem("currency", JSON.stringify(currency));
};

export const SaveEventChangedCurrency = (ChangedStatus) => {
  sessionStorage.setItem("EventChangedCurrency", JSON.stringify(ChangedStatus));
  dispatchEvent(new Event("changedCurrency"));
};

export const GetEventChangedCurrency = () => {
  const evetChangedCurrency = sessionStorage.getItem("EventChangedCurrency");
  return JSON.parse(evetChangedCurrency);
};

export const savelangLocal = (lang) => {
  localStorage.setItem("lang", JSON.stringify(lang));
};

export const getLocalLang = () => {
  const lang = localStorage.getItem("lang");
  return lang ? JSON.parse(lang) : lang;
};

export const getUser = () => {
  const userData = localStorage.getItem("user");
  return JSON.parse(userData);
};

export const removeCartsAfterOfflinePay = () => {
  localStorage.removeItem("idForCart");
  localStorage.removeItem("carts");
  sessionStorage.removeItem("AsAuth");
  sessionStorage.removeItem("CartPayGuest");
};

export const removeAfterErrorPay = () => {
  sessionStorage.removeItem("AsAuth");
  sessionStorage.removeItem("CartPayGuest");
};

export const updateUserHasCard = (newHasCardValue) => {
  let user = getUser();
  if (user) {
    user.hasCard = newHasCardValue;
    localStorage.setItem("user", JSON.stringify(user));
  }
};

export const getCartStatus = () => {
  const cartData = localStorage.getItem("haveCart");
  return JSON.parse(cartData);
};

export const saveUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const SaveToken = (token, user) => {
  localStorage.setItem("AccessToken", JSON.stringify(token));
  localStorage.setItem("user", JSON.stringify(user));
};

export const Logout = () => {
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("user");
  if (sessionStorage.getItem("toComment")) {
    sessionStorage.removeItem("toComment");
  }
  const carts = localStorage.getItem("carts");
  if (!carts || carts.length < 1) {
    localStorage.removeItem("haveCart");
  }
};

export const DeleteDataForNewCache = () => {
  localStorage.removeItem("footer_cache");
  localStorage.removeItem("footer_cache_timestamp");
  localStorage.removeItem("header_cache");
  localStorage.removeItem("header_cache_timestamp");
  localStorage.removeItem("countries_cache");
  localStorage.removeItem("countries_cache_timestamp");
};

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("AccessToken");
    const useToken = token ? JSON.parse(token) : "";
    
    if (!hasLang) {
      config.baseURL = `https://alk.hair/api/${getLocalLang()?.code || ''}`;
    }
    
    if (useToken) {
      config.headers.Authorization = `Bearer ${useToken}`;
    }

    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";

    if (!config.params) {
      config.params = {};
    }

    const localLang = getLocalLang();
    const localCurrency = getCurrencyLocal();
    if (localLang && localLang.code) {
      config.params["lang"] = localLang.code;
    }
    if (localCurrency && localCurrency.id) {
      config.params["currency"] = localCurrency.id;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response?.status === 401) {
      localStorage.removeItem("AccessToken");
    }
    throw error;
  }
);

export default axiosClient;