import { createSlice } from "@reduxjs/toolkit";
import { featchGetOrphan } from "./Orphan";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import { fetchDonationData } from "./Donation";
import { translateText } from "../Components/TranslateComponent";

const CancelSub=createSlice({

  name:"StoreCancelSub",
  initialState:{
    cancelSub:false,
  },
  reducers:{
    updateCancelSub:(state,action)=>{
      state.cancelSub=action.payload;
    },
  },
});

export const { updateCancelSub } = CancelSub.actions;

export const CancelSubAction=(payment_option,subscription_id,order_detail_id,type)=>(dispatch,getState)=>{
    const { translations } = getState().Storetranslate;

  dispatch(updateCancelSub(true))
  axiosClient.post(`/subscription/cancel`,{
    payment_option:payment_option,
    subscription_id:subscription_id,
    order_detail_id:order_detail_id
})
.then((response)=>{
    if(response.status === 200){
        if(type==='orphan'){
            dispatch(featchGetOrphan)
        }else if(type==='DonationTable'){

          dispatch(fetchDonationData(true))
          dispatchEvent(new Event('cancel_DonationTable'));
        }
        dispatch(updateCancelSub(false))
        toast.success(translateText('Completed successfully',translations));
    }
})
.catch((error)=>{
  dispatch(updateCancelSub(false))
    console.log(error);
})
}
export default CancelSub.reducer;