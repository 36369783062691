import axiosClient, {getToken} from "../axios-client";
import {useState} from "react";
import {withToken} from "../MiddelwareToken";

const GetMethods = () =>{


    const [ methods , setMethods ] = useState([]);
    const [ offlineMethods , setOfflineMethods ] = useState([]);

    const fetchMethods =  () =>{
        axiosClient.get('/payment')
            .then(response=>{
                setMethods(response.data.data.online);
                setOfflineMethods(response.data.data.offline);
                localStorage.setItem('methods',JSON.stringify(response.data.data.online))
                localStorage.setItem('offlineMethods',JSON.stringify(response.data.data.offline))
            })
            .catch(error=>{
                console.log('log' + error)
            })
    }

    const getOnlineMethods = ()=>{
        const data = localStorage.getItem('methods');
        return JSON.parse(data)
    }
    const getOfflineMethods = ()=>{
        const data = localStorage.getItem('offlineMethods');
        return JSON.parse(data)
    }
    // const getMethods = withToken(fetchMethods);
    const getMethods = fetchMethods;

    return {
        methods,
        offlineMethods,
        getMethods,
        getOnlineMethods,
        getOfflineMethods,
    };
}
export default GetMethods;