import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosClient, {
  getCurrencyLocal,
  getToken,
  formatDate,
  Logout,
  getUser,
  updateUserHasCard,
  removeCartsAfterOfflinePay,
  getUrl,
  getLocalLang,
} from "../../axios-client";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import $ from "jquery";
import { CloseIcon } from "../../Images/svgs";

import PopupFAQ from "Components/PopupFAQ";
import PaymentsComponents from "../Payment/PaymentsComponents";
import FormCard from "../FormCard";
import ValidateMsg from "../ValidateMsg";
import PopUpDate from "./PopUpDate";
import CalendarHeart from "../../Images/calenderHeart.svg";
import { ChevronLeft, InfoIcon, ShieldCheck } from "lucide-react";
import PhoneInput from "Components/Projects/FixedProject/FormData/PhoneInput";
import TranslateComponent, {
  translateText,
} from "Components/TranslateComponent";

const PopupComponent = ({
  projectId,
  symbol,
  setShowPopup,
  type,
  subscription = true,
  price,
  stepP,
  zaka_type = null,
  is_zakat = 0,
  file,
  ramadanCountry,
  ramadan,
}) => {
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const [ALLTimes, setAllTimes] = useState();

  const [loader, setLoader] = useState(false);
  const [timeDesc, setTimeDesc] = useState(!subscription ? "one_time" : "");
  const [steps, setStep] = useState(
    stepP && stepP ? stepP : ramadan ? 2 : subscription ? 1 : 3
  );
  const [newPrice, setNewPrice] = useState(price);
  const [methodType, setMethodType] = useState("");
  const [methodName, setMethodName] = useState("");
  const [methodId, setMethodId] = useState("");
  const [optionType, setOptionType] = useState(0);
  const [selectedFileShow, setSelectedFileShow] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [urlPagePayment, setUrlPagePayment] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() + 2);
        return date;
      })(),
      key: "selection",
    },
  ]);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [selectedMonthCount, setSelectedMonthCount] = useState("1");
  const [StartDate, setStartDate] = useState(1);
  const SomeTimes = ["monthly", "yearly"];
  const [forTimeMap, setForTimeMap] = useState([
    "one_time",
    "daily",
    "monthly",
    "yearly",
  ]);
  const [payNowStatus, setPayNowStatus] = useState(false);
  const [dropForDate, setDropForDate] = useState("");
  const [openDropDate, setOpenDropDate] = useState("");
  const [saveCard, setSaveCard] = useState(
    getUser() && getUser().hasCard === 1 ? true : false
  );
  const [SelectedCard, setSelectedCard] = useState("");
  const [errorsV, setErrorsV] = useState({
    cardNumber: "",
    cvc: "",
    expiry: "",
  });
  const [saveZakatStatus, setSaveZakatStatus] = useState(false);
  const [zakatTypeProject, setZakatTypeProject] = useState("");
  const [giftWay, setGiftWay] = useState(false);
  const [giftWayData, setGiftWayData] = useState({
    name: "",
    timeType: "yearly",
    donateAsOrg: false,
    subscribeToList: false,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleCheckboxChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const navigate = useNavigate();

  const hendelSetFromPopup = (data) => {
    if (dropForDate === "daily") {
      const startData = formatDate(data[0].startDate);
      const endData = formatDate(data[0].endDate);
      const FinishDate = [
        {
          startData: startData,
          endData: endData,
        },
      ];
      return FinishDate;
    } else {
      return [];
    }
  };

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axiosClient.post("payment/terms", {
          id: projectId,
          type: type,
        });
        const timesData = response.data.data;
        setAllTimes(timesData);
        console.log("API response data:", timesData);

        // Set the new price
        setNewPrice(price);
        console.log("Type :",type)

        // Check type to set timeDesc and forTimeMap accordingly
        if (
          !type,
          type === "",
          type === "zakat" ||
          type === "anaam" ||
          type === "gold" ||
          type === "zakat" ||
          type === "stock" ||
          type === "guarantee" ||
          type === "orphan" ||
          type === "orphanPublicProject" ||
          type === "orphanSpecialProject" ||
          type === "orphanGift" ||
          type === "ramadanProjects"
        ) {
          setTimeDesc("one_time");
          setForTimeMap(SomeTimes);
        } else {
          // Set forTimeMap only after ALLTimes is populated
          console.log(timesData);
          if (timesData.length > 0) {
            setForTimeMap(timesData);

            if (timesData.length === 1) {
              if (timesData[0] === "one_time") {
                setTimeDesc("one_time");
                handleNextStep();
              } else if (timesData[0] === "daily") {
                setTimeDesc("daily");
              } else if (timesData[0] === "monthly") {
                setTimeDesc("monthly");
              } else if (timesData[0] === "yearly") {
                setTimeDesc("yearly");
              }

            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [newPrice]); // Removed ALLTimes from the dependency array
  useEffect(() => {
    if (forTimeMap.length === 1) {
    if (timeDesc === "one_time") {
      handleNextStep();
    }
    if (timeDesc === "daily") {
      setOpenDropDate(true);
      setDropForDate("daily");
    }
    if (timeDesc === "monthly") {
      setOpenDropDate(true);
      setDropForDate("monthly");
    }
    if (timeDesc === "yearly") {
      setOpenDropDate(true);
      setDropForDate("yearly");
    }
  }
  }, [timeDesc]);
  useEffect(() => {
    const handelChangePrice = () => {
      setNewPrice(localStorage.getItem("price"));
    };
    window.addEventListener("priceChanged", handelChangePrice);
    return () => {
      window.removeEventListener("priceChanged", handelChangePrice);
    };
  }, []);
  useEffect(() => {
    const handelPayNowEvent = () => {
      setPayNowStatus(true);
      setStep(3);
    };
    window.addEventListener("payNow", handelPayNowEvent);
    return () => {
      window.removeEventListener("payNow", handelPayNowEvent);
    };
  }, []);
  useEffect(() => {
    if (methodName === "stripe") {
      setOptionType(1);
    } else if (methodType === "online" && methodName !== "stripe") {
      setOptionType(2);
    } else if (methodType === "offline") {
      setOptionType(3);
    }
    $(".rdrDateInput.rdrDateDisplayItem input").attr("readonly", true);
  }, [steps, methodName, methodType, openDropDate]);

  const handleChangePrice = (e) => {
    setNewPrice(e.target.value);
  };
  const handleNextStep = () => {
    setOpenDropDate(false);
    if (steps < 3) {
      if (timeDesc) {
        setStep((prevStep) => Math.min(prevStep + 1, 5));
      } else {
        // toast.info(translateText("Select Time!", translations));
      }
    } else if (steps === 3) {
      if (!giftWay) {
        if (methodType) {
          setStep((prevStep) => Math.min(prevStep + 1, 5));
        } else {
          toast.info(translateText("Choose payment method!", translations));
        }
      } else {
        setStep((prevStep) => Math.min(prevStep + 1, 5));
      }
    } else if (steps >= 4) {
      if (!giftWay) {
        setStep((prevStep) => Math.min(prevStep + 1, 5));
      } else {
        if (methodType) {
          setStep((prevStep) => Math.min(prevStep + 1, 5));
        } else {
          toast.info(translateText("Choose payment method!", translations));
        }
      }
    }
  };
  const handleGiftWayChange = () => {
    setGiftWay(!giftWay);
    setTimeDesc("one_time");
  };
  const handleGiftWayData = (e) => {
    const { name, value, type, checked } = e.target;
    setGiftWayData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    console.log(giftWayData);
  };
  const handleGiftWayTimeData = (type) => {
    setGiftWayData((prevState) => ({
      ...prevState,
      timeType: type,
    }));

    handleNextStep();
  };

  const handleBackStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, subscription ? 1 : 3));
  };
  const getMethod = (name, type, methodId) => {
    setMethodName(name);
    setMethodType(type);
    setMethodId(methodId);
    localStorage.setItem("methodName", name);
    localStorage.setItem("methodType", type);
    localStorage.setItem("methodId", methodId);
  };
  const handleChangeDate = (e) => {
    const input = e.target.value;
    if (/^[0-9/]*$/.test(input)) {
      if (
        input.length === 2 &&
        expiry.length === 1 &&
        input.charAt(1) !== "/"
      ) {
        setExpiry(input + "/");
      } else if (input.length <= 5) {
        setExpiry(input);
      }
      setErrorsV((prevData) => ({
        ...prevData,
        expiry: "",
      }));
    }
  };
  const handleChangeCvc = (e) => {
    const input = e.target.value;
    if (/^[0-9]*$/.test(input) && input.length <= 3) {
      setCvc(input);
      setErrorsV((prevData) => ({
        ...prevData,
        cvc: "",
      }));
    }
  };
  const handleChangeNumber = (e) => {
    const input = e.target.value;
    if (/^[0-9]*$/.test(input) && input.length <= 16) {
      setCardNumber(input);
      setErrorsV((prevData) => ({
        ...prevData,
        cardNumber: "",
      }));
    }
  };
  const handleFileChange = (file) => {
    if (file) {
      setSelectedFileShow(URL.createObjectURL(file));
      setSelectedFile(file);
    }
  };
  const removeForPayDirect = () => {
    setShowPopup(false);
    setStep(subscription ? 1 : 3);
    // setNewPrice("");
    setOptionType(0);
    if (ramadan && ramadan === true) {
      setTimeDesc("one_time");
    } else {
      setTimeDesc("");
    }
    setPayNowStatus(false);
    setOpenDropDate(false);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setDropForDate("");
    setOpenDropDate("");
    setSelectedMonthCount("1");
    localStorage.removeItem("targetsDetails");
    localStorage.removeItem("methodType");
    localStorage.removeItem("methodName");
    localStorage.removeItem("methodId");
    // localStorage.removeItem("price");
    localStorage.removeItem("ProjectIsZakat");
    sessionStorage.removeItem("countryId_fixed");
    sessionStorage.removeItem("type_fixed");
    sessionStorage.removeItem("desc_fixed");
    if (sessionStorage.getItem("AsAuth") === "1") {
      Logout();
      sessionStorage.removeItem("AsAuth");
    }
    dispatchEvent(new Event("removeForPayDirect"));
  };
  const handlePayment = async () => {
    if (optionType === 2 && isConfirmed === false) {
      toast.error(
        translateText(
          "Please, You need to accept our policies before donating.",
          translations
        )
      );
    } else {
      const token = getToken();
      const lang = getLocalLang()?.code;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      setLoader(true);
      const formData = new FormData();
      formData.append(
        "payment_option",
        methodType === "offline" ? methodType : methodName
      );
      if (
        type === "orphan" ||
        type === "orphanGift" ||
        type === "orphanPublicProject" ||
        type === "orphanSpecialProject" ||
        type === "giftOrphanDonation"
      ) {
        formData.append(
          "payment_option",
          methodType === "offline"
            ? methodType
            : "orphan" + capitalizeFirstLetter(methodName)
        );
      }
      localStorage.setItem("methodType", methodType);
      formData.append("payment_term", timeDesc && timeDesc !== 0 ? timeDesc : "one_time");

      formData.append(
        "price",
        newPrice ? newPrice : localStorage.getItem("price")
      );
      formData.append("type", type);
      formData.append("lang", lang);
      if (ramadan) {
        formData.append("ramadanCountry", ramadanCountry);
      }
      formData.append("type_id", projectId);
      formData.append("currency_id", getCurrencyLocal().id);

      if (sessionStorage.getItem("countryId_fixed") !== "") {
        formData.append(
          "countryId_fixed",
          sessionStorage.getItem("countryId_fixed")
        );
      }

      if (sessionStorage.getItem("type_fixed") !== "") {
        formData.append("type_fixed", sessionStorage.getItem("type_fixed"));
      }

      if (sessionStorage.getItem("desc_fixed") !== "") {
        formData.append("desc_fixed", sessionStorage.getItem("desc_fixed"));
      }

      if (file) {
        console.log(file);
        formData.append("file_fixed", file);
      }

      if (localStorage.getItem("targetsDetails")) {
        formData.append(
          "targetsDetails",
          JSON.parse(localStorage.getItem("targetsDetails"))
        );
      }
      if (zaka_type || zakatTypeProject) {
        formData.append("zaka_type", zaka_type || zakatTypeProject);
      }
      if (saveZakatStatus || is_zakat) {
        formData.append("is_zakat", 1);
      }

      if (dropForDate === "daily") {
        const Date = hendelSetFromPopup(state);
        formData.append("start_date", Date[0].startData);
        formData.append("end_date", Date[0].endData);
      } else if (dropForDate === "monthly" || dropForDate === "yearly") {
        formData.append("start_date", StartDate);
        if (selectedMonthCount && dropForDate !== "yearly") {
          formData.append("end_date", selectedMonthCount);
        }
      }
      if (methodName === "stripe") {
        if (SelectedCard) {
          formData.append("saved_card_id", SelectedCard);
        } else {
          if (cardNumber && cvc && expiry) {
            const [card_exp_month, card_exp_year] = expiry.split("/");
            formData.append("card_number", cardNumber);
            formData.append("card_exp_month", card_exp_month);
            formData.append("card_exp_year", card_exp_year);
            formData.append("card_cvc", cvc);
            if (saveCard) {
              formData.append("save_card", saveCard ? 1 : 0);
            }
          } else {
            setLoader(false);
            let errors = {};
            if (!cardNumber) {
              errors.cardNumber = translateText(
                "Please enter a valid Card Number",
                translations
              );
            }
            if (!cvc) {
              errors.cvc = translateText(
                "Please enter a valid cvc",
                translations
              );
            }
            if (!expiry) {
              errors.expiry = translateText(
                "Please enter a valid Expiry",
                translations
              );
            }
            setErrorsV((prevData) => ({
              ...prevData,
              ...errors,
            }));
            return;
          }
        }
      }
      try {
        if (methodType === "offline") {
          formData.append("payment_offline_file", selectedFile);
          formData.append("payment_offline_id", methodId);

          await axios
            .post(`${getUrl()}/payment/direct`, formData, config)
            .then((response) => {
              localStorage.setItem(
                "order",
                JSON.stringify(response.data.data.orders)
              );
              removeForPayDirect();
              if (!giftWay) {
                navigate(`/${getLocalLang()?.code}/success-direct`);
              } else {
                localStorage.set("gifter_name", giftWayData.firstName);
                localStorage.set("gifter_amount", newPrice);
                navigate(`/${getLocalLang()?.code}/success-gift`);
              }
            })
            .catch((error) => {
              toast.error("" + error?.response?.data?.message, translations);
            });
        } else if (methodType === "online") {
          await axios
            .post(`${getUrl()}/payment/direct`, formData, config)
            .then((reposnse) => {
              localStorage.setItem(
                "order",
                JSON.stringify(reposnse.data.data.orders)
              );
              if (methodName === "stripe") {
                if (reposnse.data.data.hasCard === 1) {
                  updateUserHasCard(1);
                  dispatchEvent(new Event("loading"));
                }
                if (sessionStorage.getItem("CartPayGuest")) {
                  removeCartsAfterOfflinePay();
                  Logout();
                }
                removeForPayDirect();
                if (reposnse.data.data.url) {
                  if (!giftWay) {
                    navigate(`/${getLocalLang()?.code}/success-direct`);
                  } else {
                    navigate(`/${getLocalLang()?.code}/success-gift`);
                  }
                } else {
                  navigate(`/${getLocalLang()?.code}/error-direct`);
                }
              } else if (methodName === "paypal" || methodName === "iyzico") {
                localStorage.setItem(
                  "order",
                  JSON.stringify(reposnse.data.data.orders)
                );
                setUrlPagePayment(reposnse.data.data.url);
              } else if (methodName === "binance") {
                window.location.href = reposnse.data.data.url.checkoutUrl;
              }
            })
            .catch((error) => {
              toast.error(
                "" + translateText(error?.response?.data?.message, translations)
              );
              console.log(error);
            });
        }
      } catch (error) {
        console.error("Payment error:", error);
        toast.error(error || "Payment error", translations);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <dialog id={`my_modal_4_${type}`} className="modal">
      <div className="modal-box  w-[370px] max-w-xl md:w-[400px] px-4 max-h-[550px]  sm:max-h-[650px] overflow-auto	relative">
        {getToken() ? (
          <>
            <div className="grid grid-cols-1 ">
              <div className="flex gap-2 p-3 px-4 rounded-b-xl justify-center items-center text-base font-semibold absolute top-[0] right-[0px] ">
                <div className="text-blue-700 flex gap-x-2">
                  <ShieldCheck className="w-5 h-5" />
                  <TranslateComponent word={"Secure Donation"} />
                </div>
                <PopupFAQ />
              </div>
              <div className="col-span-2 lg:col-span-4 md:w-4/ m-auto mt-2 ">
                <ul className="steps w-full px-4 my-3  md:px-0 md:gap-2 ">
                  {subscription === true ? (
                    <>
                      {giftWay && (
                        <li
                          data-content={`${steps === 5 ? "✓" : "5"}`}
                          className={`step ${
                            steps === 5 ? "step-primary" : ""
                          } `}
                        ></li>
                      )}
                      <li
                        data-content={`${steps >= 4 ? "✓" : "➤"}`}
                        className={`step ${steps >= 4 ? "step-primary" : ""} `}
                      ></li>
                      <li
                        data-content={`${steps >= 3 ? "✓" : "➤"}`}
                        className={`step ${steps >= 3 ? "step-primary" : ""} `}
                      ></li>
                      <li
                        data-content={`${steps >= 2 ? "✓" : "➤"}`}
                        className={`step ${steps >= 2 ? "step-primary" : ""} `}
                      ></li>
                      <li data-content="✓" className={`step step-primary`}></li>
                    </>
                  ) : (
                    <>
                      {giftWay && (
                        <li
                          data-content={`${steps === 5 ? "✓" : "5"}`}
                          className={`step ${
                            steps === 5 ? "step-primary" : ""
                          } `}
                        ></li>
                      )}
                      <li
                        data-content={`${steps >= 4 ? "✓" : "4"}`}
                        className="step step-primary"
                      ></li>
                      <li
                        data-content={`${steps >= 3 ? "✓" : "3"}`}
                        className="step step-primary"
                      ></li>
                    </>
                  )}
                </ul>
              </div>
              <div className="modal-action col-span-1 lg:col-span-1 absolute top-[-24px] left-0">
                <button
                  onClick={removeForPayDirect}
                  className="btn rounded-l-none rounded-t-none border-white btn-outline"
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            {urlPagePayment ? (
              <iframe
                src={urlPagePayment}
                width="100%"
                height="600px"
                title="Content Frame"
                style={{ border: "none" }}
              ></iframe>
            ) : (
              <>
                {!ramadan && subscription && steps === 1 && (
                  <>
                    <h2 className="text-center">
                      {translateText("Donation options", translations)}
                    </h2>
                    <br />
                    <p className="text-center">
                      {translateText(
                        "Donate an amount either once or automatically paid periodically",
                        translations
                      )}
                    </p>
                    <div
                      className="menu menu-horizontal justify-evenly rounded-box w-[100%]"
                      role="group"
                    >
                      {type === "gift" ? (
                        <div className="py-2 px-3 text-lg" role="group">
                          <button
                            onClick={() => setTimeDesc("one_time")}
                            className={`border-2 border-neutral-700 ${
                              timeDesc === "one_time" ? "bg-blue-600" : ""
                            } rounded-lg px-4 py-2 hover:bg-neutral-700`}
                          >
                            {translateText("One Time", translations)}
                          </button>
                        </div>
                      ) : (
                        <>
                          {forTimeMap ? (
                            forTimeMap.map((desc, index) => (
                              <div
                                key={index}
                                className="py-2 text-lg"
                                role="group"
                              >
                                <button
                                  onClick={() => {
                                    setTimeDesc(desc);
                                    if (desc === "one_time") {
                                      setOpenDropDate(false);
                                      setDropForDate(desc);
                                    }else{
                                      setOpenDropDate(true);
                                      setDropForDate(desc);
                                    }
                                  }}
                                  className={`border-2 border-blue-600 ${
                                    timeDesc === desc
                                      ? "bg-blue-600 text-white"
                                      : ""
                                  } rounded-lg px-4 py-2 hover:bg-blue-700 hover:text-white`}
                                >
                                  {desc === "one_time"
                                    ? translateText("One Time", translations)
                                    : desc === "daily"
                                    ? translateText("Daily", translations)
                                    : desc === "monthly"
                                    ? translateText("Monthly", translations)
                                    : translateText("Yearly", translations)}
                                </button>
                              </div>
                            ))
                          ) : (
                            <>
                              <TranslateComponent word={"Please wait ..."} />
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="flex flex-col gap-4 justify-start mx-10 my-1">
                      {giftWay && (
                        <input
                          className="border border-gray-700 rounded-lg p-2 mb-2"
                          placeholder={translateText(
                            "Name of honored person",
                            translations
                          )}
                          name="name"
                          value={giftWayData.name}
                          onChange={(e) => handleGiftWayData(e)}
                        />
                      )}
                    </div>
                  </>
                )}

                {openDropDate && (
                  <PopUpDate
                    setSelectedMonthCount={setSelectedMonthCount}
                    selectedMonthCount={selectedMonthCount}
                    setState={setState}
                    state={state}
                    type={dropForDate}
                    StartDate={StartDate}
                    setStartDate={setStartDate}
                    price={price}
                  />
                )}
                {steps === 2 && (
                  <>
                    {subscription && timeDesc && giftWay === false ? (
                      <>
                        <div className=" flex flex-col items-center">
                          <div className="card w-100 bg-base-100 p-5">
                            <div className="card-body p-0 m-0">
                              <h2 className="card-title">
                                {translateText("Total", translations)}
                              </h2>
                              <p>
                                {translateText(
                                  "You can modify the amount from here...",
                                  translations
                                )}
                              </p>
                              <div className="card-actions">
                                <div className="grid grid-cols-3 gap-2">
                                  <div className="col-span-2 flex items-center">
                                    <input
                                      min={0}
                                      type="number"
                                      placeholder={translateText(
                                        "Enter the amount here",
                                        translations
                                      )}
                                      value={ price}
                                      disabled={
                                        type === "orphan" ? true : false
                                      }
                                      onChange={handleChangePrice}
                                      className="border p-2 w-full rounded text-black"
                                    />
                                  </div>
                                  <div className="col-span-1 flex items-center">
                                    <span className=" bg-blue-700 rounded text-white p-2">
                                      {getCurrencyLocal()?.name}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center my-6">
                        <div className="p-2 w-full bg-orange-100 flex gap-x-2 justify-center items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="red"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                              clipRule="evenodd"
                            />
                          </svg>
                          مهدي الي {giftWayData.name}
                        </div>
                        <img src={CalendarHeart} className="w-32 mt-6" />
                        <p className="mt-4 text-center w-full px-8 font-semibold text-[15px] text-gray-600">
                          <TranslateComponent word={"You can donate"} />
                          {newPrice}
                          <TranslateComponent
                            word={
                              "automatically every year by becoming an annual donor."
                            }
                          />
                        </p>
                        <p className="mt-4 text-center w-full px-8 font-semibold text-[15px] text-gray-600">
                          <TranslateComponent
                            word={
                              "Your continued support helps us fulfill our mission throughout the year."
                            }
                          />
                        </p>

                        <div className="flex flex-col w-full px-4 gap-2 mt-24">
                          <button
                            name="timeType"
                            className="w-full p-3 bg-pink-600 text-white rounded-md"
                            onClick={() => handleGiftWayTimeData("yearly")}
                          >
                            <TranslateComponent word={"Donate"} /> {newPrice}{" "}
                            <TranslateComponent word={"Yearly"} />
                          </button>
                          <button
                            name="timeType"
                            onClick={() => handleGiftWayTimeData("one_time")}
                            className="w-full p-3 bg-white text-black border border-gray-300 rounded-md"
                          >
                            <TranslateComponent word={"Donate"} /> {newPrice}{" "}
                            <TranslateComponent word={"Once"} />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {steps === 3 && (
                  <>
                    {!giftWay ? (
                      <PaymentsComponents
                        zakatTypeProject={zakatTypeProject}
                        setZakatTypeProject={setZakatTypeProject}
                        saveZakatStatus={saveZakatStatus}
                        setSaveZakatStatus={setSaveZakatStatus}
                        type={type}
                        timeDesc={timeDesc}
                        newPriceUpdate={newPrice}
                        symbol={symbol}
                        getMethod={getMethod}
                      />
                    ) : (
                      <div className="flex flex-col justify-center items-center my-6">
                        <div className="p-2 w-full bg-orange-100 flex gap-x-2 justify-center items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="red"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                              clipRule="evenodd"
                            />
                          </svg>
                          مهدي الي {giftWayData.name}
                        </div>
                        <div className="w-full flex justify-center items-center border-y relative p-2">
                          <h2 className="font-semibold text-lg">
                            أدخل التفاصيل الخاصة بك
                          </h2>
                          <ChevronLeft
                            onClick={handleBackStep}
                            className="absolute top-1/ left-4 cursor-pointer"
                          />
                        </div>
                        <div className="flex flex-col w-full px-4 gap-4 mt-4">
                          <div className="flex flex-col rounded-lg border overflow-hidden shadow-sm border-gray-300">
                            <input
                              type="text"
                              className="border-b p-2 border-gray-300"
                              placeholder="الاسم الأول"
                              name="firstName"
                              value={giftWayData.firstName}
                              onChange={handleGiftWayData}
                            />
                            <input
                              type="text"
                              className="border p-2"
                              placeholder="الاسم الأخير"
                              name="lastName"
                              value={giftWayData.lastName}
                              onChange={handleGiftWayData}
                            />
                          </div>

                          <div className="flex flex-col rounded-lg border overflow-hidden shadow-sm border-gray-300">
                            <input
                              type="text"
                              className="border-b p-2 border-gray-300"
                              placeholder="عنوان البريد الالكتروني"
                              name="email"
                              value={giftWayData.email}
                              onChange={handleGiftWayData}
                            />
                          </div>
                          <PhoneInput
                            value={giftWayData.phoneNumber}
                            onChange={handleGiftWayData}
                          />
                        </div>

                        <div className="mt-4 flex flex-col gap-2 w-full px-4">
                          <div className="flex gap-2">
                            <input
                              type="checkbox"
                              name="donateAsOrg"
                              checked={giftWayData.donateAsOrg}
                              onChange={handleGiftWayData}
                            />
                            <TranslateComponent
                              word={"Donate as an organization"}
                            />
                          </div>

                          <div className="flex gap-2">
                            <input
                              type="checkbox"
                              name="subscribeToList"
                              checked={giftWayData.subscribeToList}
                              onChange={handleGiftWayData}
                            />
                            <TranslateComponent
                              word={"Subscribe to our mailing list"}
                            />
                          </div>
                        </div>
                        <div className="w-full px-4 mt-24">
                          <button
                            onClick={handleNextStep}
                            className="bg-green-500 p-2 px-3 rounded-lg text-white w-full text-lg"
                          >
                            متابعة
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {steps === 4 && (
                  <>
                    {!giftWay ? (
                      <FormCard
                        SelectedCard={SelectedCard}
                        setSelectedCard={setSelectedCard}
                        setSaveCard={setSaveCard}
                        saveCard={saveCard}
                        newPrice={newPrice}
                        selectedFile={selectedFile}
                        selectedFileShow={selectedFileShow}
                        handleFileChange={handleFileChange}
                        cardNumber={cardNumber}
                        expiry={expiry}
                        hadelChangeCvc={handleChangeCvc}
                        cvc={cvc}
                        methodId={methodId}
                        optionType={optionType}
                        handelChangeNumber={handleChangeNumber}
                        Changedate={handleChangeDate}
                        errorsV={errorsV}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    ) : (
                      <>
                        <div>
                          <div className="w-full flex justify-center items-center border-y relative p-2">
                            <h2 className="font-semibold text-lg">
                              <TranslateComponent
                                word={"Value of your donation"}
                              />
                            </h2>

                            <ChevronLeft
                              onClick={handleBackStep}
                              className="absolute top-1/ left-4 cursor-pointer"
                            />
                          </div>
                          <div className="p-8 flex gap-x-2 justify-center items-end text-4xl font-bold">
                            {getCurrencyLocal()?.code} {newPrice}
                            <span className="text-sm mb-[3px]">
                              ILS/
                              {giftWayData.timeType === "yearly" ? (
                                <>عام</>
                              ) : (
                                <>مرة واحدة</>
                              )}
                            </span>
                          </div>
                          <div className="W-full p-4 bg-blue-600 justify-center items-center flex gap-2 text-white">
                            <input
                              type="checkbox"
                              checked={giftWay}
                              onChange={handleGiftWayChange}
                            />{" "}
                            تغطية تكاليف المعاملة
                            <InfoIcon className="w-4 h-4" />
                          </div>
                          <div className="p-4">
                            <PaymentsComponents
                              zakatTypeProject={zakatTypeProject}
                              setZakatTypeProject={setZakatTypeProject}
                              saveZakatStatus={saveZakatStatus}
                              setSaveZakatStatus={setSaveZakatStatus}
                              type={type}
                              timeDesc={timeDesc | 'one_time'}
                              newPriceUpdate={newPrice}
                              symbol={symbol}
                              getMethod={getMethod}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {steps === 5 && giftWay && (
                  <div className="p-4">
                    <FormCard
                      SelectedCard={SelectedCard}
                      setSelectedCard={setSelectedCard}
                      setSaveCard={setSaveCard}
                      saveCard={saveCard}
                      newPrice={newPrice}
                      selectedFile={selectedFile}
                      selectedFileShow={selectedFileShow}
                      handleFileChange={handleFileChange}
                      cardNumber={cardNumber}
                      expiry={expiry}
                      hadelChangeCvc={handleChangeCvc}
                      cvc={cvc}
                      methodId={methodId}
                      optionType={optionType}
                      handelChangeNumber={handleChangeNumber}
                      Changedate={handleChangeDate}
                      errorsV={errorsV}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  </div>
                )}
                {giftWay && steps > 1 ? (
                  <>
                    {steps === 4 ? (
                      <>
                        {!giftWay ? (
                          <>
                            {loader ? (
                              <div className="flex justify-center items-center w-[50%] py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm">
                                <span className="loading loading-dots loading-lg"></span>
                              </div>
                            ) : (
                              <div
                                role="button"
                                onClick={handlePayment}
                                className="flex justify-center items-center w-[25%] mx-3 py-2 mt-4 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm
                                                          hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D] "
                              >
                                {translateText("Complete", translations)}
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            role="button"
                            onClick={handleNextStep}
                            className="flex justify-center items-center w-[25%] mx-3 py-2 mt-4 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D]"
                          >
                            {translateText("Next", translations)}
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {steps === 5 && (
                      <div
                        role="button"
                        onClick={handlePayment}
                        className="flex justify-center items-center w-[92%] mx-3 py-2 mt-4 text-sm font-semibold leading-5 text-white bg-pink-500 rounded-lg border border-pink-500 shadow-sm
                                                                              hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D] "
                      >
                        {translateText("Send Gift", translations)}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center mt-4">
                    {loader ? (
                      <div className="flex justify-center items-center w-[50%] py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm">
                        <span className="loading loading-dots loading-lg"></span>
                      </div>
                    ) : (
                      <>
                        {steps > (subscription ? 1 : 3) ? (
                          <>
                            {steps === 4 ? (
                              <div
                                role="button"
                                onClick={handlePayment}
                                className="flex justify-center items-center w-[25%] mx-3 py-2 mt-4 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm
                                                          hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D] "
                              >
                                {translateText("Complete", translations)}
                              </div>
                            ) : (
                              <div
                                role="button"
                                onClick={handleNextStep}
                                className="flex justify-center items-center w-[25%] mx-3 py-2 mt-4 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D]"
                              >
                                {translateText("Next", translations)}
                              </div>
                            )}
                            {payNowStatus ? (
                              ""
                            ) : (
                              <>
                              {ALLTimes.length === 1 && ALLTimes[0] === "one_time" ? (
                              <></>
                              ):(<div
                                role="button"
                                onClick={handleBackStep}
                                className="flex justify-center items-center w-[25%] mx-3 py-2 mt-4 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D]"
                              >
                                {translateText("Back", translations)}
                              </div>) }
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            role="button"
                            onClick={handleNextStep}
                            className="flex justify-center items-center w-[50%] py-2  text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm         hover:bg-[#FCD34D]  hover:text-black   hover:border-0"
                          >
                            {translateText("Next", translations)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <ValidateMsg msg={"NO Ue"} />
        )}
      </div>
    </dialog>
  );
};
export default PopupComponent;
