import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient from "../../../axios-client";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TranslateComponent from "../../TranslateComponent";
import { useDispatch, useSelector } from "react-redux";
import { setCrumb } from "../../../Managment/CrumpRedux";

function SuccessStoryPost() {
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [crumps, setCrumbs] = useState([{ name: "Success Stories", link: "" }]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axiosClient.get(`post/${slug}`);
        console.log("AAA", response);
        setPost(response.data.data);
        setCrumbs([
            { name: "Success Stories", link: "" },
            { name: response.data.data.name, link: "" },
          ]);
      } catch (err) {
        setError("Error fetching the success story data");
      } finally {
        setLoading(false);
      }
    };

    const containerWidth = () => {
      const navContainers = document.querySelectorAll(".nav-container");
      navContainers.forEach((navContainer) => {
        navContainer.classList.remove(
          "container",
          "max-w-7xl",
          "mx-auto",
          "px-2"
        );
      });
    };

    fetchPost();
    containerWidth();
  }, [slug]);


  useEffect(() => {
    dispatch(setCrumb(crumps));
  }, [crumps]);

  if (loading) return <div>Loading...</div>;

  // if (error) {
  //     toast.error(error,translations );
  //     return null;
  // }

  // Social Media URLs

  return (
    <>
      {/* Success Story Content */}
      <div className="flex flex-col  relative story-image">
        <LazyLoadImage
          loading="lazy"
          src={post.image}
          className="w-full object-cover h-[400px] max-md:max-w-full relative"
          alt={post.name}
        />
        <div className="relative  bottom-20 text-center flex z-10 flex-col self-center p-8 mt-0 w-full text-lg font-medium leading-7 bg-white rounded-2xl max-w-[1200px] text-zinc-900 max-md:px-5 max-md:max-w-full">
          <div className="text-5xl font-bold text-black max-md:max-w-full">
            {post.name}
          </div>
          <div className="mt-8 max-md:max-w-full">
            <div dangerouslySetInnerHTML={{ __html: post.description }} />
          </div>
        </div>
        <div className="flex flex-col mt-20 container max-w-7xl mx-auto">
          <div className="self-start text-3xl font-bold text-right whitespace-nowrap text-zinc-900">
            {post.galleryTitle || (
              <TranslateComponent word={"Pictures of success stories"} />
            )}
          </div>
          <div className="mt-8 w-full max-md:max-w-full">
            <div className="grid grid-cols-2 px-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
              {post.gallery &&
                post.gallery.map((image, index) => (
                  <div
                    key={index}
                    className="flex flex-col max-md:ml-0 max-md:w-full"
                  >
                    <div className="flex text-center overflow-hidden relative flex-col grow justify-center items-center px-16 pt-60 pb-4 text-2xl font-bold text-white whitespace-nowrap rounded-md aspect-[1.31] max-md:px-5 max-md:pt-10 max-md:mt-5">
                      <LazyLoadImage
                        loading="lazy"
                        src={image.image}
                        className="object-cover absolute inset-0 w-full h-full"
                        alt={image.name}
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                      <span className="absolute mb-10">{image.name}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessStoryPost;
