import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PlusCircle, CreditCard, Calendar, Lock, X, FileText } from "lucide-react";
import { handelRemoveCard } from "../Managment/PayCards";
import { getCurrencyLocal } from "../axios-client";
import TranslateComponent from "./TranslateComponent";
import InputValidation from "./InputValidetion";
import FileUploader from "./FileUploader";
import { CloseIcon, MasterIcon, VisaIcon } from "../Images/svgs";

const ACCEPTED_FILE_TYPES = {
  'application/pdf': { icon: FileText, name: 'PDF' },
  'application/msword': { icon: FileText, name: 'Word' },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { icon: FileText, name: 'Word' },
  'image/jpeg': { icon: FileText, name: 'Image' },
  'image/png': { icon: FileText, name: 'Image' },
};

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const FormCard = ({
  setSaveCard,
  saveCard,
  newPrice,
  SelectedCard,
  setSelectedCard,
  optionType,
  handelChangeNumber,
  Changedate,
  hadelChangeCvc,
  cardNumber,
  expiry,
  cvc,
  selectedFile,
  selectedFileShow,
  handleFileChange,
  errorsV,
  onCheckboxChange,
}) => {
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.Storetranslate.translations);
  const Cards = useSelector((state) => state.StorePayCards.Cards);
  const removeLoader = useSelector((state) => state.StorePayCards.RemoveLoader);
  const offlineMethods = useMemo(() => JSON.parse(localStorage.getItem("offlineMethods") || "[]"), []);

  const [showFormCard, setShowFormCard] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [fileError, setFileError] = useState("");

  const handleFilePreview = (file) => {
    if (!file) return null;

    if (file.size > MAX_FILE_SIZE) {
      setFileError("File size should not exceed 5MB");
      return null;
    }

    if (!ACCEPTED_FILE_TYPES[file.type]) {
      setFileError("Invalid file type. Please upload PDF, Word, or image files.");
      return null;
    }

    setFileError("");
    return URL.createObjectURL(file);
  };

  const FilePreviewSection = () => {
    if (!selectedFile) return null;

    const FileIcon = ACCEPTED_FILE_TYPES[selectedFile.type]?.icon || FileText;

    return (
      <div className="bg-white rounded-lg shadow-sm border p-4">
        <div className="flex items-center gap-4">
          <FileIcon className="h-8 w-8 text-blue-500" />
          <div className="flex-1">
            <p className="font-medium">{selectedFile.name}</p>
            <p className="text-sm text-gray-500">
              {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
            </p>
          </div>
          <button
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            onClick={() => handleFileChange(null)}
          >
            <X className="h-4 w-4" />
          </button>
        </div>
        {selectedFile.type === "application/pdf" && (
          <div className="mt-4 h-96 border rounded">
            <iframe
              src={selectedFileShow}
              className="w-full h-full"
              title="PDF Preview"
            />
          </div>
        )}
        {selectedFile.type.startsWith("image/") && (
          <div className="mt-4">
            <LazyLoadImage
              src={selectedFileShow}
              className="max-h-96 w-full object-contain"
              alt="Preview"
            />
          </div>
        )}
      </div>
    );
  };
  const handleCheckboxChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onCheckboxChange(newValue); // Pass the new value to the parent
  };

  const OfflineMethodsSection = () => (
    <div className="flex flex-col gap-4 mb-6">
      {offlineMethods.map((item, index) => (
        <div
          key={index}
          className="bg-white p-6 rounded-xl border transition-shadow"
        >
          <LazyLoadImage
            src={item.image}
            alt={item.name}
            className="w-32 h-32 mx-auto rounded-lg object-cover mb-4"
          />
          <h2 className="text-xl font-bold text-center text-gray-800 mb-4">
            {item.name}
          </h2>
          <div className="space-y-2 text-sm text-gray-600">
            {Object.entries(item.derails[0]).map(([key, value]) => (
              <p key={key}>
                <span className="font-medium">
                  <TranslateComponent word={key.replace("_", " ")} />:{" "}
                </span>
                <span className="text-gray-800">{value}</span>
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  const handleRemoveCard = (id) => {
    dispatch(handelRemoveCard(id));
  };

  const renderCardForm = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-gray-700 font-semibold mb-1">
          <TranslateComponent word={"Card Number"} />
        </label>
        <div className="relative">
          <CreditCard className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="number"
            onChange={handelChangeNumber}
            value={cardNumber}
            placeholder="1234 5678 9012 3456"
            className="w-full pl-10 p-3 text-gray-700 border rounded-lg focus:ring-blue-400 focus:border-blue-400"
          />
        </div>
        <InputValidation msg={errorsV.cardNumber} />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700 font-semibold mb-1">
            <TranslateComponent word={"Expiry Date"} />
          </label>
          <div className="relative">
            <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              onChange={Changedate}
              value={expiry}
              placeholder="MM/YY"
              className="w-full pl-10 p-3 text-gray-700 border rounded-lg focus:ring-blue-400 focus:border-blue-400"
            />
          </div>
          <InputValidation msg={errorsV.expiry} />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-1">CVC</label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              onChange={hadelChangeCvc}
              value={cvc}
              placeholder="123"
              className="w-full pl-10 p-3 text-gray-700 border rounded-lg focus:ring-blue-400 focus:border-blue-400"
            />
          </div>
          <InputValidation msg={errorsV.cvc} />
        </div>
      </div>

      <div className="flex items-center gap-3 mt-4">
        <input
          type="checkbox"
          checked={saveCard}
          onChange={() => setSaveCard(!saveCard)}
          className="h-5 w-5 text-blue-600 focus:ring-blue-400 border-gray-300 rounded"
        />
        <label className="text-gray-700 font-semibold">
          <TranslateComponent word={"Save Card"} />
        </label>
      </div>
    </div>
  );

  const renderSavedCards = () => (
    <div className="space-y-3">
      {Cards.map((item) => (
        <div
          key={item.id}
          className={`p-4 rounded-xl border-2 transition-colors ${
            SelectedCard === item.id
              ? "bg-blue-100 border-blue-400"
              : "bg-gray-50 border-gray-300"
          } flex items-center justify-between`}
        >
          <div
            role="button"
            onClick={() => {
              setSelectedCard(item.id);
              setShowFormCard(false);
            }}
            className="flex-1 flex items-center justify-between text-gray-600"
          >
            <span className="text-lg font-semibold">{item.last4}</span>
            <span className="text-lg font-semibold">{item.brand}</span>
            <span className="text-lg font-semibold">
              {item.exp_month} / {item.exp_year}
            </span>
            {item.brand === "visa" ? <VisaIcon /> : <MasterIcon />}
          </div>
          {removeLoader ? (
            <span className="text-sm text-gray-500">
              <TranslateComponent word={"Removing"} />
            </span>
          ) : (
            <button
              onClick={() => handleRemoveCard(item.id)}
              className="p-2 text-gray-500 hover:text-red-500 transition-colors"
            >
              <CloseIcon />
            </button>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="space-y-6">
          {optionType === 1 && (
        <>
          {renderSavedCards()}
          <div className="collapse collapse-arrow bg-white p-5 rounded-xl border">
            {Cards.length > 0 && (
              <input
                className="hidden"
                type="radio"
                name="accordion"
                onChange={() => {
                  setShowFormCard(!showFormCard);
                  setSelectedCard(null);
                }}
              />
            )}
            {!showFormCard ? (
              <div
                onClick={() => {
                  setShowFormCard(true);
                  setSelectedCard(null);
                }}
                className="flex items-center justify-center gap-2 text-xl text-blue-600 cursor-pointer hover:text-blue-700 transition-colors"
              >
                <PlusCircle className="w-6 h-6" />
                <TranslateComponent word={"Add New Card"} />
              </div>
            ) : (
              renderCardForm()
            )}
          </div>
          <div className="p-4 bg-blue-50 rounded-xl border-2 border-blue-400">
            <div className="flex justify-between items-center text-gray-700">
              <span className="font-medium">
                <TranslateComponent word={"Your Donation"} />
              </span>
              <span className="font-bold text-lg">
                {newPrice} {getCurrencyLocal()?.name}
              </span>
            </div>
          </div>
        </>
      )}

      {optionType === 2 && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 rounded-md">
          
          <div className="mt-2">
            <label className="inline-flex gap-x-2 items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="form-checkbox"
              />
              <p className="text-yellow-800">
              <TranslateComponent word={"Confirmation and approval"} />
              </p>
            </label>
          </div>
        </div>
      )}


      {optionType === 3 && (
        <div className="space-y-6">
          <OfflineMethodsSection />
          <div className="bg-white rounded-lg shadow-sm border">
            <div className="p-4 border-b">
              <h2 className="text-lg font-semibold">
                <TranslateComponent word={"Upload Payment Receipt"} />
              </h2>
            </div>
            <div className="p-6">
              {fileError && (
                <div className="mb-4 bg-red-50 border-l-4 border-red-500 p-4 rounded-lg text-red-700">
                  {fileError}
                </div>
              )}
              <FilePreviewSection />
              <FileUploader
                handleFile={(file) => {
                  const preview = handleFilePreview(file);
                  if (preview) {
                    handleFileChange(file);
                  }
                }}
                text={<TranslateComponent word={"Upload File"} />}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormCard;