import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const CACHE_KEY = 'footer_cache';
const CACHE_TIMESTAMP_KEY = 'footer_cache_timestamp';
const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000;

const Footer = createSlice({
  name: "StoreFooter",
  initialState: {
    data: [],
    loader: false,
  },
  reducers: {
    UpdateDataState: (state, action) => {
      state.data = action.payload;
    },
    UpdateLoaderState: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const { UpdateDataState, UpdateLoaderState } = Footer.actions;

export const featchFooter = () => async (dispatch) => {
  const now = Date.now();
  const cacheTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
  const cachedDataString = localStorage.getItem(CACHE_KEY);
  const cachedData = JSON.parse(cachedDataString)||[];
  if (cachedData.length>0 && cacheTimestamp && (now - cacheTimestamp < CACHE_DURATION)) {
    // Use cached footer data
    console.log('Using cached footer data');
    dispatch(UpdateDataState(cachedData));
    dispatch(UpdateLoaderState(false));
    return;
  }
  dispatch(UpdateLoaderState(true));
  try {
    const response = await axiosClient.get('/footer');
    const footerData = response.data;

    localStorage.setItem(CACHE_KEY, JSON.stringify(footerData));
    localStorage.setItem(CACHE_TIMESTAMP_KEY, now.toString());

    console.log('Fetched and cached new footer data');
    dispatch(UpdateDataState(footerData));
  } catch (error) {
    console.error('Error fetching footer data:', error);
  } finally {
    dispatch(UpdateLoaderState(false));
  }
}

export default Footer.reducer;
