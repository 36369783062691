import { createSlice } from '@reduxjs/toolkit';
import axiosClient from '../axios-client';

const CACHE_KEY_PREFIX = 'translations_cache_'; // Prefix for cache keys
const CACHE_TIMESTAMP_KEY_PREFIX = 'translations_cache_timestamp_'; // Prefix for timestamp keys
const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export const initialState = {
    translations: {},
};

export const Translate = createSlice({
    name: 'Storetranslate',
    initialState,
    reducers: {
        setTranslations: (state, action) => {
            state.translations = action.payload;
        },
    },
});

export const { setTranslations } = Translate.actions;

export const fetchTranslations = () => async (dispatch) => {
    const lang = JSON.parse(localStorage.getItem('lang'))?.code ?? 'ar'; // Get current language code from local storage
    const now = Date.now();
    const cacheKey = `${CACHE_KEY_PREFIX}${lang}`; // Cache key specific to the current language
    const cacheTimestampKey = `${CACHE_TIMESTAMP_KEY_PREFIX}${lang}`; // Timestamp key specific to the current language
    const cacheTimestamp = localStorage.getItem(cacheTimestampKey);
    const cachedTranslations = JSON.parse(localStorage.getItem(cacheKey));

    if (cachedTranslations && cacheTimestamp && (now - cacheTimestamp < CACHE_DURATION)) {
        dispatch(setTranslations(cachedTranslations));
        return;
    }

    try {
        const response = await axiosClient.get('/translate', {
            params: {
                lang: lang,
            },
        });
        const translationData = response.data.data;

        const newTranslations = {};
        translationData.forEach((translation) => {
            if (!newTranslations[translation.lang]) {
                newTranslations[translation.lang] = {};
            }
            newTranslations[translation.lang][translation.lang_key] = translation.lang_value;
        });

        // Save the translations for the current language in local storage
        localStorage.setItem(cacheKey, JSON.stringify(newTranslations));
        localStorage.setItem(cacheTimestampKey, now.toString());

        dispatch(setTranslations(newTranslations));
    } catch (error) {
        console.error('Error fetching translations:', error);
    }
};

export const translateur = (key) => (state) => {
    const langKey = key.replace(/[^A-Za-z0-9_\sءاأإآؤئبتثجحخدذرزسشصضطظعغفقكلمنهويةى]/gu, '').replace(/[\s_]+/g, '_');
    const lang = JSON.parse(localStorage.getItem('lang'))?.code ?? 'ar';

    if (!state || Object.keys(state).length === 0) {
        return '';
    } else {
        const languageTranslations = state[lang];

        if (languageTranslations) {
            if (languageTranslations[langKey]) {
                return languageTranslations[langKey];
            }
        }
    }
    return key;
};

const sendTranslationRequest = async (key, langKey, lang) => {
    try {
        await axiosClient.post('/save/translate', {
            key: key,
            lang_key: langKey,
            lang: lang,
        });
    } catch (error) {
        console.error('Error sending translation request:', error);
    }
};

export default Translate.reducer;
