import {createSlice} from "@reduxjs/toolkit";
import axiosClient  from "../axios-client";

const Blog = createSlice({
    name:"StoreBlog",
    initialState:{
        Blogs:[],
        PagCount:'',
        CurrentPage:'',
    },

    reducers:{
        getBlogs:(state,action)=>{
            state.Blogs=[...state.Blogs, ...action.payload]
        },
        clearBlogs:(state,action)=>{
            state.Blogs=action.payload
        },
        getPagCount:(state,action)=>{
            state.PagCount=action.payload
        },
        getCurrentPage:(state,action)=>{
            state.CurrentPage=action.payload
        },
    }

})

export const {getBlogs,clearBlogs,getPagCount,getCurrentPage}=Blog.actions;

export const fetchBlogPostsFilter = (word = null, sort = '' ,category_id='',page=1)=>async(dispatch)  => {
    try {
        const response = await axiosClient.get('/posts/blog', {
            params: {
                limit: 12,
                page:page,
                search: word,
                sort: sort,
                category:category_id
            },
        });
        if (response.data.status) {
            const { data } = response.data.data;
            const {pagination} =response.data.data;
            const {current_page} = pagination;
            const {total_pages} = pagination;
            dispatch(getBlogs(data));
            dispatch(getCurrentPage(current_page));
            dispatch(getPagCount(total_pages));
        }
    } catch (error) {
        console.error('Error fetching blog posts:', error);
    }
};

export const showSomeBlog = (word,sort)=> async (dispatch)=>{
    dispatch(clearBlogs([]))
}

export default Blog.reducer;