import {useEffect, useState} from "react";
import GetMethods from "./Components/GetMethods";
import {useDispatch, useSelector} from "react-redux";
import {fetchCountries} from "./Managment/Countires";
import {setSelectedValue} from "./Managment/Slice";
import {getCurrencyLocal, getLocalLang, getUser} from "./axios-client";
import {fetchCurrency} from "./Managment/Currencies";
import {fetchLang} from "./Managment/Lang";
import { fetchTranslations } from "./Managment/Translate";
import { fetchHeaders  } from "./Managment/Header";
import {fetchUserData} from "./Managment/ClientData";
import {fetchCards} from "./Managment/PayCards";

const FirstData = () =>{
    const { getMethods } = GetMethods();
    const dispatch = useDispatch();
    const LocalCurrency = getCurrencyLocal();
    const LocalLang = getLocalLang();
    const DataAll =useSelector(state=>state.StoreCountries.countries);
    const language = useSelector(state => state.Storelang.langs);
    const currencies = useSelector(state=>state.StoreCurrency.currencies);
    const [loadeMeter , setLoadeMeter] = useState(0);
    useEffect(() => {
        dispatch(fetchTranslations());
        dispatch(fetchHeaders());
        dispatch(fetchCountries());

        dispatch(fetchUserData);
        getMethods();

        if(getUser() && getUser().hasCard === 1){
            dispatch(fetchCards);
        }
    }, []);
    // useEffect(() => {
    //     if(DataAll.length<1 && loadeMeter<5){
    //         dispatch(fetchCountries());
    //         setLoadeMeter(prevData=>prevData+1);
    //     }
    // }, [DataAll]);
    useEffect(()=>{
        const handelGetCards =()=>{
            if(getUser() && getUser().hasCard === 1){
                dispatch(fetchCards);
            }
        }
        window.addEventListener('payAsAuth',handelGetCards);

        return ()=>{
            window.removeEventListener('payAsAuth',handelGetCards);
        }
    },[])




    useEffect(() => {
        if(!language.length > 0){
            dispatch(fetchLang)
        }
        if(!currencies.length > 0){
            dispatch(fetchCurrency);
        }

        if(!LocalCurrency){
            if (currencies.length > 0) {
                dispatch(setSelectedValue({ index:0,
                    value:currencies[0]?.name,
                    code_id:currencies[0]?.id,
                    type:'currency',
                    LangIcon_CurValue:currencies[0]?.value,
                    code:currencies[0]?.code
                }));
            }
        }else{
            const currencyId = getCurrencyLocal() && getCurrencyLocal().id;
            const currency  = currencies.filter((item,index)=>item.id === currencyId);
            if(currency.length>0){
                dispatch(setSelectedValue({ index:0,
                    value:currency[0]?.name,
                    code_id:currency[0]?.id,
                    type:'currency',
                    LangIcon_CurValue:currency[0]?.value,
                    code:currency[0]?.code
                }));
            }else{
                dispatch(setSelectedValue({ index:0, value:getCurrencyLocal().name , code_id:getCurrencyLocal().id, type:'currency' ,LangIcon_CurValue:getCurrencyLocal().value,code:getCurrencyLocal().code}));
            }
        }

        if (!LocalLang) {
            if (language.length > 0) {
                dispatch(setSelectedValue({
                    index: 0,
                    value: language[0]?.name,
                    type: 'lang',
                    code_id: language[0]?.lang,
                    LangIcon_CurValue:language[0].icon
                }));
            }
        }else{
            dispatch(setSelectedValue({index: 0, value: getLocalLang().name, type: 'lang', code_id: getLocalLang().code,LangIcon_CurValue:getLocalLang().icon}));

        }

    }, [language,currencies]);



}
export default FirstData;