import React from 'react';
import { useMatches, Link, useParams } from 'react-router-dom';
import { HomeIcon, RightArrow } from '../../Images/svgs';
import { truncateText } from "../../axios-client";
import { useMediaQuery } from "react-responsive";
import { translateText } from "../TranslateComponent";
import { useSelector } from "react-redux";
import CustomLink from "../../CustomLink";

export const useBreadcrumbs = (mat, parm) => {
    const matches = useMatches() ?? mat;
    const params = useParams() ?? parm;

    const breadcrumbs = matches.map(({ pathname, handle }) => ({
        path: pathname,
        crumbs: handle && handle.crumb ? handle.crumb(params) : null,
    }));

    return breadcrumbs;
};

const Breadcrumbs = ({ name, allData }) => {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const breadcrumbs = useBreadcrumbs();
    const isTabletScreen = useMediaQuery({ query: '(min-width: 600px) and (max-width: 786px)' });

    return (
        <nav className="hidden md:flex flex-col justify-center items-end px-16 py-4 text-sm font-medium text-right text-white bg-blue-600 max-md:px-5">
            <ul className="container max-w-7xl mx-auto md:flex md:flex-row items-center self-start gap-4 px-1.5 py-1">
                <li>
                    <CustomLink to="/">
                        <HomeIcon />
                    </CustomLink>
                </li>
                {breadcrumbs.length > 0 && <RightArrow />}
                {name ? (
                    <li> {translateText(name,translations)}</li>
                ) : (
                    allData && allData.length > 0 ? (
                        allData.map((data, index) => (
                            <React.Fragment key={index}>
                                <li>
                                    <CustomLink to={`/${data.link}`}>
                                        {translateText(data.name,translations)}
                                    </CustomLink>
                                </li>
                                {index < allData.length - 1 && <li> <RightArrow /> </li>}
                            </React.Fragment>
                        ))
                    ) : (
                        breadcrumbs
                            .filter(breadcrumb => breadcrumb.crumbs)
                            .map((breadcrumb, index) => (
                                <React.Fragment key={index}>
                                    {Array.isArray(breadcrumb.crumbs) && breadcrumb.crumbs.length > 0 ? (
                                        breadcrumb.crumbs.map((crumb, crumbIndex) => (
                                            <React.Fragment key={crumbIndex}>
                                                <li>
                                                    <CustomLink className="grow whitespace-nowrap" to={breadcrumb.path}>
                                                        {isTabletScreen ? truncateText(translateText(crumb, translations), 10) : translateText(crumb, translations)}
                                                    </CustomLink>
                                                </li>
                                                {crumbIndex !== breadcrumb.crumbs.length - 1 && <RightArrow />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <>
                                            {translateText(breadcrumbs[1]?.crumbs.props?.word, translations) || translateText(breadcrumbs[0]?.crumbs, translations) || translateText(breadcrumbs[1]?.crumbs, translations)}
                                        </>
                                    )}
                                </React.Fragment>
                            ))
                    )
                )}
            </ul>
        </nav>
    );
};

export default Breadcrumbs;
