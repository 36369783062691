import React from 'react';

function InputField({ label, placeholder, type = 'text',name,onChange ,value}) {
    return (
        <div className="flex flex-col pt-1 mt-1.5 w-full">
            <label className="self-start text-base font-medium leading-none text-slate-700">
                {label}
            </label>
            <input
                onChange={onChange}
                name={name}
                type={type}
                className="flex overflow-hidden gap-2 items-center px-3.5 py-2.5 mt-3.5 w-full text-sm text-start whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm min-h-[41px]"
                placeholder={placeholder}
                value={value}
            />
        </div>
    );
}

export default InputField;