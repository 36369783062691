import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { getLocalLang, getToken } from "./axios-client";
import { CanLogin, CanGoToPayment } from "./Components/MiddlewareForRoute";
import AsUser from "./AsUser";
import CategoryProject from "./Components/CategoryProject";
import LanguageWrapper from "./LanguageWrapper";
import FixedProjects from "./Components/FixedProjects";
import SingleFixedProject from "./Components/Projects/SingleFixedProject";
import SuccessStoryPost from "Components/Pages/SuccessStories/SuccessStoryPost";

const PaymentComponent = React.lazy(() =>
  import("./Components/PaymentSteps/PaymentComponent")
);
const SuccessResultDirect = React.lazy(() =>
  import("./Components/Payment/SuccessResultDirect")
);
const SuccessGiftDirect = React.lazy(() =>
  import("./Components/Payment/SuccessGiftDirect")
);
const ContactForm = React.lazy(() => import("./Components/Forms/ContactForm"));
const PartnerForm = React.lazy(() => import("./Components/Forms/PartnerForm"));
const OrphanDetails = React.lazy(() =>
  import("./Components/Orphan/OrphanDetails")
);
const ErrorResultDirect = React.lazy(() =>
  import("./Components/Payment/ErrorResultDirect")
);
const ProjectsAll = React.lazy(() =>
  import("./Components/Projects/ProjectsAll")
);
const ErrorBoundary = React.lazy(() => import("./ErrorBoundary"));
const SliderShow = React.lazy(() => import("./Components/SliderShow"));
const OrphanShow = React.lazy(() => import("./Components/Orphan/OrphanShow"));
const Kafalat = React.lazy(() => import("./Components/Kafalat"));
const KafalaePage = React.lazy(() =>
  import("./Components/Kafalat/KafalaePage")
);
const CampaignComponent = React.lazy(() =>
  import("./Components/Projects/CampaignComponent")
);
const CampaignDetailes = React.lazy(() =>
  import("./Components/Projects/CampaignDetailes")
);
const ZakatPage = React.lazy(() => import("./Components/ZakatPage"));
const Sanadiq = React.lazy(() => import("./Components/Boxies/Sanadiq"));
const SanadiqBox = React.lazy(() => import("./Components/Boxies/SanadiqBox"));
const AllNewsComponent = React.lazy(() =>
  import("./Components/AllNewsComponent")
);
const Home = React.lazy(() => import("./Components/Home"));
const VolunteerForm = React.lazy(() =>
  import("./Components/Forms/VolunteerForm")
);
const SerchResult = React.lazy(() => import("./Components/SerchResult"));
const AnyPages = React.lazy(() => import("./Components/AnyPages"));
const Login = React.lazy(() => import("./Components/Login"));
const Register = React.lazy(() => import("./Components/Register"));
const Profile = React.lazy(() => import("./Components/Profile"));
const ForgotPassword = React.lazy(() => import("./Components/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./Components/ResetPassword"));
const NotFoundPage = React.lazy(() => import("./Components/NotFoundPage"));
const MainLayout = React.lazy(() => import("./Components/Layouts/MainLayout"));
const BlogPost = React.lazy(() => import("./Components/Pages/blog/BlogPost"));
const Project = React.lazy(() => import("./Components/Pages/projects/Project"));
const SuccessStory = React.lazy(() =>
  import("./Components/Pages/SuccessStories/SuccessStory")
);
const Country = React.lazy(() => import("./Components/Pages/Country"));
const Vision = React.lazy(() => import("./Components/Pages/Vision"));
const WeInNumbers = React.lazy(() => import("./Components/Pages/WeInNumbers"));
const OurOffice = React.lazy(() => import("./Components/Pages/OurOffice"));
const MostQuestioned = React.lazy(() =>
  import("./Components/BuilderComponents/Faq/MostQuestioned")
);
const Privacy = React.lazy(() => import("./Components/Pages/Privacy"));
const Gift = React.lazy(() => import("./Components/Pages/Gift"));
const SocialMedia = React.lazy(() => import("./Components/Pages/SocialMedia"));
const MediaReleases = React.lazy(() =>
  import("./Components/Pages/MediaReleases")
);
const Events = React.lazy(() => import("./Components/Pages/Events"));
const WhatDidTheySay = React.lazy(() =>
  import("./Components/Pages/WhatDidTheySay")
);
const Builder = React.lazy(() => import("./Components/Builder"));
const BasketComponent = React.lazy(() =>
  import("./Components/Cart/BasketComponent")
);
const ResultAsCategory = React.lazy(() =>
  import("./Components/ResultAsCategory")
);
const Zzz = React.lazy(() => import("./Components/Zzz"));
const ZZZinner = React.lazy(() => import("./Components/ZZZinner"));

const ProtectedRoute = ({ children }) => {
  return getToken() ? (
    children
  ) : (
    <Navigate to={`/${getLocalLang()?.code}/login`} />
  );
};

const PublicOnlyRoute = ({ children }) => {
  return getToken() ? <Navigate to={`/${getLocalLang()?.code}`} /> : children;
};
window.addEventListener("dontHaveCart", () => {
  return <Navigate to={`/${getLocalLang()?.code}`} />;
});
const routes = [
  {
    path: "/:lang",
    element: <LanguageWrapper />,
    children: [
      { path: "", element: <Home />, handle: { crumb: () => "Home" } },
      { path: ":any", element: <AnyPages /> },
      { path: "campaign/:any", element: <AnyPages url={"campaign/"} /> },
      {
        path: "builder",
        element: <Builder />,
        handle: { crumb: () => "builder" },
      },
      { path: "MainProject/:slug", element: <FixedProjects /> },
      { path: "loginAsOwner/:token", element: <AsUser /> },
      { path: "singleFixedProject/:slug", element: <SingleFixedProject /> },
      {
        path: "",
        element: <MainLayout />,
        children: [
          { path: "CategoryProject/:slug", element: <CategoryProject /> },
          { path: "sadaqa", element: <Zzz /> },
          { path: "zakat-page/:typeData/:slug", element: <ZZZinner /> },
          {
            path: "login",
            element: (
              <PublicOnlyRoute>
                <Login />
              </PublicOnlyRoute>
            ),
          },
          {
            path: "register",
            element: (
              <PublicOnlyRoute>
                <Register />
              </PublicOnlyRoute>
            ),
          },
          {
            path: "forgot-password",
            element: (
              <PublicOnlyRoute>
                <ForgotPassword />
              </PublicOnlyRoute>
            ),
          },
          { path: "reset-password", element: <ResetPassword /> },
          {
            path: "profile",
            element: (
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            ),
          },
          { path: "country/:slug", element: <Country /> },
          { path: "vision", element: <Vision /> },
          { path: "we-in-numbers", element: <WeInNumbers /> },
          { path: "our-office", element: <OurOffice /> },
          { path: "most-questioned", element: <MostQuestioned /> },
          { path: "privacy_policy", element: <Privacy /> },
          {
            path: "cart",
            element: <CanGoToPayment Component={BasketComponent} />,
          },
          {
            path: "payment",
            element: <CanLogin Component={PaymentComponent} />,
          },
          { path: "success-direct", element: <SuccessResultDirect /> },
          { path: "success-gift", element: <SuccessGiftDirect /> },
          { path: "error-direct", element: <ErrorResultDirect /> },
          { path: "volunteer-form", element: <VolunteerForm /> },
          { path: "contact-us", element: <ContactForm /> },
          { path: "partner-form", element: <PartnerForm /> },
          { path: "orphan-details", element: <OrphanDetails /> },
          { path: "more-gallery", element: <SliderShow /> },
          { path: "guarantee", element: <Kafalat /> },
          { path: "social-media", element: <SocialMedia /> },
          { path: "media-releases", element: <MediaReleases /> },
          { path: "events", element: <Events /> },
          { path: "spoken", element: <WhatDidTheySay /> },
          { path: "success-stories", element: <SuccessStory /> },
          { path: "success-stories/:slug", element: <SuccessStoryPost /> },
          { path: "campaigns-page", element: <CampaignComponent /> },
          { path: "boxes", element: <Sanadiq /> },
          { path: "boxes/:slug", element: <SanadiqBox /> },
          { path: "search-result/:campaign", element: <SerchResult /> },
          { path: "category/:slug", element: <ResultAsCategory /> },
          { path: "post/:postType/:slug", element: <BlogPost /> },
          { path: "guarantee/:slug", element: <KafalaePage /> },
          { path: "orphan/:slug", element: <OrphanShow /> },
          { path: "projects", element: <ProjectsAll /> },
          { path: "project/:slug", element: <Project /> },
          { path: "campaignPage/:slug", element: <CampaignDetailes /> },
          { path: "t/:slug", element: <AllNewsComponent /> },
          { path: "gift", element: <Gift /> },
        ],
      },
      { path: "*", element: <NotFoundPage />, handle: { crumb: () => "404" } },
      {
        path: "404",
        element: <NotFoundPage />,
        handle: { crumb: () => "404" },
      },
    ],
  },
];
const addErrorBoundary = (route) => ({
  ...route,
  errorElement: <ErrorBoundary />,
});

const Router =
  sessionStorage.getItem("AsAuth") === "1"
    ? createBrowserRouter([
        {
          path: "/:lang",
          element: <LanguageWrapper />,
          errorElement: <ErrorBoundary />,
          children: [
            {
              path: "payment",
              element: <CanLogin Component={PaymentComponent} />,
              handle: { crumb: () => "payment" },
              errorElement: <ErrorBoundary />,
            },
          ],
        },
      ])
    : createBrowserRouter(routes.map((route) => addErrorBoundary(route)));

export default Router;
