import React, {useEffect, useState} from 'react';
import { ArrowDown } from "../../../../Images/svgs";

function SelectField({ label, options ,setCheckTextArea,setCheckFile,type,selectedType,setSelectedType,selectedCountry,setSelectedCountry,handelFetchCountryByType}) {
    const [openSelect, setOpenSelect] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const toggleSelect = () => {
        setOpenSelect(!openSelect);
    };
    const handleOptionClick = (option) => {
        if(type === 'country'){
            setSelectedCountry(option)
            // sessionStorage.setItem('countryId_pay',option?.id)
            setCheckFile(option.file);
            setCheckTextArea(option.textArea);
        }else{
            handelFetchCountryByType(option.id)
            setSelectedType(option)
        }
        setSelectedOption(option);
        setOpenSelect(false);
    };
    useEffect(() => {
        if(options.length>0){
            if(type === 'country'){
                setSelectedCountry(options[0])
            }else{
                setSelectedType(options[0])
            }
            setSelectedOption(options[0])
        }
    }, [options]);

    return (
        <>
            <div className="mt-6 w-full relative">
                <label className="text-base font-medium leading-none text-slate-700">
                    {label}
                </label>
                <div
                    className="flex overflow-hidden gap-2 items-center px-3.5 py-3 mt-6 w-full text-base font-bold text-center text-black bg-white rounded-lg border border-gray-300 border-solid shadow-sm min-h-[44px] cursor-pointer"
                    onClick={toggleSelect} // Toggle dropdown on click
                >
                                        {selectedOption?.flag && (
                        <img
                            loading="lazy"
                            src={selectedOption.flag}
                            alt=""
                            className="object-contain shrink-0 self-stretch my-auto w-8 aspect-[1.33]"
                        />
                    )}
                    <div className="flex-1 shrink gap-2 self-stretch text-start my-auto min-w-[200px]">
                        {selectedOption?.name} {/* Show selected option name */}
                    </div>
                    <ArrowDown />

                </div>

                {openSelect && (
                    <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="p-2 hover:bg-gray-200 cursor-pointer flex items-center"
                                onClick={() => handleOptionClick(option)} // Handle option selection
                            >
                                {option.flag && (
                                    <img
                                        loading="lazy"
                                        src={option.flag}
                                        alt=""
                                        className="object-contain w-6 h-4 m-2"
                                    />
                                )}
                                {option.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default SelectField;
