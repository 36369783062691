import {useEffect, useState} from "react";
import React from "react";
import TranslateComponent, {translateText} from "../TranslateComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GetMethods from "../GetMethods";
import {useSelector} from "react-redux";

const PaymentsComponents =  ({
 zakatTypeProject,
 setZakatTypeProject,
 setSaveZakatStatus,
 saveZakatStatus,
 timeDesc,
 newPriceUpdate,
 symbol,
 getMethod,
 type
}) =>{
    const translations = useSelector((state) => state.Storetranslate.translations);
    const {getOnlineMethods,getOfflineMethods}=GetMethods();
    const methods= getOnlineMethods();
    const offlineMethods = getOfflineMethods();
    const [newPrice,setNewPrice]=useState('');
    const [active, setActive]=useState(localStorage.getItem('methodName')??'');
    window.addEventListener('priceChanged',()=>{
        setNewPrice(localStorage.getItem('price'));
    })
    useEffect(() => {
        getMethod(localStorage.getItem('methodName'),localStorage.getItem('methodType'),localStorage.getItem('methodId'))
        setNewPrice(newPriceUpdate!==''?newPriceUpdate:localStorage.getItem('price'))
    }, []);
    const saveZakat =(data)=>{
        if(data==='zakat' && type==='project'){
            setSaveZakatStatus(!saveZakatStatus);
        }
    }
    const zakatData=[
        {
            name:'money',
            id:1
        },
        {
            name:'anaam',
            id:2
        },
        {
            name:'gold',
            id:3
        },
        {
            name:'stock',
            id:4
        }
    ]
    return(
        <div
            className="flex flex-col px-4 py-5 w-full  rounded-lg border border-solid border-black border-opacity-20 mt-2">
            <div className="flex text-3xl font-bold tracking-wide leading-10 text-center text-black">
                <TranslateComponent word={'Donation_details'}/>

            </div>
            <div className="flex flex-col justify-center p-5 mt-4 rounded-lg border border-solid border-neutral-200">
                <div className="flex gap-1.5 justify-between">
                    <div className="my-auto text-base font-medium text-black ">
                        <TranslateComponent word={'Total'}/>
                    </div>

                    <div className="text-xl font-bold text-right text-blue-600">

                        {newPrice ?? '0'} {symbol ? symbol : '$'}
                    </div>
                </div>
            </div>
            <div className="mt-5 text-lg font-medium tracking-wide leading-4 text-right text-black">
                <TranslateComponent word={'Payment Method'}/>
            </div>

            <div className={`grid  gap-2.5 grid-cols-2`}>
                {methods && methods.length > 0 && methods.map((item, index) => {
                    return (
                        <>
                            {/* eslint-disable-next-line no-mixed-operators */}
                            {type === 'orphan' && item.name === 'stripe' || item.name==='paypal' ? (

                                    <>
                                        <div
                                            key={index}
                                            className="col-span-1 w-full flex items-center justify-between px-1 py-1.5 mt-4 text-sm font-medium text-white  rounded-lg border border-solid border-stone-300  max-md:flex-wrap max-md:px-5">
                                            <div className="flex items-center justify-between max-md:flex-wrap">
                                                <input onChange={() => getMethod(item.name, 'online', '')}
                                                       onClick={() => setActive(item.name)} id="sms" type="radio"
                                                       name="methods" className="radio m-auto"
                                                       checked={active === item.name}
                                                />
                                                <LazyLoadImage
                                                    loading="lazy"
                                                    src={item.image}
                                                    className="shrink-0 my-auto w-9 aspect-[1.49] object-cover object-cover"
                                                    alt="Pay"
                                                />
                                                <div className="flex-1 max-md:max-w-full  text-black">{item.name}</div>
                                            </div>
                                        </div>
                                    </>

                            ) : (
                                timeDesc !== 'one_time' ? (
                                    item.name !== 'binance' && (
                                        <div
                                            key={index}
                                            className="col-span-1 w-full flex items-center justify-between px-1 py-1.5 mt-4 text-sm font-medium text-white  rounded-lg border border-solid border-stone-300  max-md:flex-wrap max-md:pr-5">
                                            <div className="flex items-center justify-between gap-2.5">
                                                <input onChange={() => getMethod(item.name, 'online', '')}
                                                       onClick={() => setActive(item.name)} id="sms" type="radio"
                                                       name="methods" className="radio" checked={active === item.name}
                                                />
                                                <LazyLoadImage
                                                    loading="lazy"
                                                    src={item.image}
                                                    className="shrink-0 my-auto w-9 aspect-[1.49] object-cover"
                                                    alt="Pay"
                                                />
                                                <div className="flex-1 max-md:max-w-full  text-black">{item.type}</div>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div
                                        key={index}
                                        className="col-span-1  justify-between px-2  py-2.5 mt-4 text-sm font-medium text-white  rounded-lg border border-solid border-stone-300">
                                        <div className="flex items-center justify-between gap-2.5">
                                            <input onChange={() => getMethod(item.name, 'online', '')}
                                                   onClick={() => setActive(item.name)} id="sms" type="radio"
                                                   name="methods" className="radio m-auto"
                                                   checked={active === item.name}
                                            />
                                            <LazyLoadImage
                                                loading="lazy"
                                                src={item.image}
                                                className="shrink-0 my-auto w-9 aspect-[1.49] object-cover"
                                                alt="Pay"
                                            />
                                            <div className="flex-1 max-md:max-w-full text-nowrap text-black">{item.type}</div>
                                        </div>
                                    </div>
                                )

                            )}
                        </>

                    )
                })}
                {timeDesc === 'one_time' && type !== 'orphan' && (
                    <>
                        {offlineMethods?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex gap-2.5 justify-between px-2 py-2.5 mt-4 text-sm font-medium text-white rounded-lg border border-solid border-stone-300 overflow-x-auto  ">
                                    <div className="flex gap-2.5">
                                        <input onChange={() => getMethod(item.name, 'offline', item.id)}
                                               onClick={() => setActive(item.name)} id="sms" type="radio" name="methods"
                                               className="radio m-auto" checked={active === item.name}/>
                                        <LazyLoadImage
                                            loading="lazy"
                                            src={item.image}
                                            className="shrink-0 my-auto w-9 aspect-[1.49] object-cover"
                                            alt="Pay"
                                        />
                                        <div className="flex-1 max-md:max-w-full text-black">{item.name}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}

            </div>
            {type === 'project' && localStorage.getItem('ProjectIsZakat') === '1'&& (
                <div className={'flex items-center justify-center mt-10'}>
                    <div className="mx-5">
                        <button onClick={() => saveZakat('zakat')}
                                className={`flex justify-center items-center ${saveZakatStatus && 'bg-gray-400 text-white'} border border-gray-800 px-1 py-2.5 rounded-lg shadow-sm`}>
                            {/*<HeartIcon fill={saveZakatStatus && 'white'}/>*/}
                            <TranslateComponent word={'Save Zakat'}/>
                        </button>
                    </div>

                    {saveZakatStatus && (
                        <select
                            className="select select-bordered w-full max-w-xs mx-5"
                            value={zakatTypeProject}
                            onChange={(e) => setZakatTypeProject(e.target.value)}
                        >
                            <option disabled selected><TranslateComponent word={'Chose zakat type'}/> ! </option>
                            {zakatData.map((item, index) => (
                                <option key={index} value={item.name}>
                                    {translateText(item.name,translations)}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

            )}

        </div>
    )
}
export default PaymentsComponents