import React from "react";
import {truncateText} from "../../../axios-client";

function Header({title , description}) {
    return (
        <header className="text-white xl:h-[270px] 2xl:h-[400px] h-[auto] my-10 lg:my-10 pl-6 mr-6 md:mr-0 gap-y-4 items-start row-start-1 md:row-start-2 col-start-1 col-end-3  self-start md:self-start md:mt-20 md:col-end-1 lg:col-end-3  grid content-end rtl:ml-auto ltr:mr-0 ltr:ml-6 ltr:col-end-3 ltr:md:col-end-2 ltr:lg:ml-0 ">

            <div className="flex relative max-h-[160px] min-h-[160px] w-full">
                <div className="w-full max-md:max-w-full">
                    <h1 className="text-4xl font-bold max-md:max-w-full">{title}</h1>
                    <p className="mt-2.5 text-xl font-medium max-md:max-w-full">
                        <div dangerouslySetInnerHTML={{__html: truncateText(description,160)}}/>
                    </p>
                </div>
            </div>
        </header>
    );
}

export default Header;