import {getCartStatus, getLocalLang, getToken} from "../axios-client";
import {Navigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

const CanGoToPayment = ({ Component }) => {
    const [token, setToken] = useState(null);
    const [cartStatus, setCartStatus] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const tokenValue = getToken();
        const cartStatusValue = getCartStatus();

        setToken(tokenValue);
        setCartStatus(cartStatusValue);
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (token === null && cartStatus === null) {
        return <Navigate to={`/${getLocalLang()?.code}/login`}/>;
    } else if (cartStatus === null) {
        return <Navigate to={`/${getLocalLang()?.code}/login`} />;
    } else {
        return <Component />;
    }
};
const CanLogin = ({ Component }) => {
    const [token, setToken] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const tokenValue = getToken();
        setToken(tokenValue);
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (token !== null) {
        return <Component />;
    } else {
        return <Navigate to={`/${getLocalLang()?.code}/login`} />;
    }
};

export { CanGoToPayment, CanLogin };
