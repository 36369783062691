import React, {Suspense, useEffect, useState} from 'react';
import { RouterProvider } from 'react-router-dom';
import { FacebookProvider } from "@kazion/react-facebook-login";
import Router from './Router';
import './css/index.css';
import './css/custom.css';
import { createRoot , hydrateRoot} from 'react-dom/client';
import 'react-quill/dist/quill.snow.css';
import { ContextProvider } from './Providers/ContextProvider';
import {Provider} from 'react-redux';
import store from './Managment/store';
import 'react-toastify/dist/ReactToastify.css';
import './css/bottomNav.css';
import Loader from './Components/Loader/Loader';
import { ToastContainer } from 'react-toastify';
import axiosClient, {getCurrencyLocal, getLocalLang, getToken} from './axios-client';
import FirstData from "./FirstData";
import { HelmetProvider } from 'react-helmet-async';
import { UserAgent } from "react-useragent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Socisl from "./Components/Socisl";

const App = () => {
    const {decryptedGoogleKey, decryptedFacebookKey} = Socisl();
    const [forLoading, setForLoading] = useState(false);

    // Function to handle the top scroll when a blog post page is loaded
    useEffect(() => {
        const handleTopZero = () => window.scrollTo(0, 0);
        window.addEventListener('loadBlogPostPage', handleTopZero);
        return () => window.removeEventListener('loadBlogPostPage', handleTopZero);
    }, []);

    // Combined function to handle both currency and language changes in a single API call
    const updateAttributes = async (currency, lang) => {
        if (getToken()) {
            const payload = {};
            if (currency) payload.currency = currency.id;
            if (lang) payload.lang = lang.code;

            if (Object.keys(payload).length) {
                await axiosClient.post('/update/attribute', payload);
            }
        }
    };

    // Function to handle language direction change and batching the API call
    useEffect(() => {
        const handleChangedLang = async () => {
            const lang = getLocalLang();
            const currency = getCurrencyLocal();

            if (lang) {
                document.documentElement.setAttribute('dir', lang.code === 'ar' ? 'rtl' : 'ltr');
                await updateAttributes(currency, lang);
            }
        };

        window.addEventListener('LangChanged', handleChangedLang);
        return () => window.removeEventListener('LangChanged', handleChangedLang);
    }, []);

    // Function to handle currency changes and batching the API call
    useEffect(() => {
        const handleChangedCurrency = async () => {
            const currency = getCurrencyLocal();
            const lang = getLocalLang();

            if (currency) {
                await updateAttributes(currency, lang);
            }
        };

        window.addEventListener('changedCurrency', handleChangedCurrency);
        return () => window.removeEventListener('changedCurrency', handleChangedCurrency);
    }, []);

    // Reloading the page when `forLoading` state is true
    useEffect(() => {
        if (forLoading) {
            window.location.reload();
        }
    }, [forLoading]);

    // Adding the loading event listener to set `forLoading`
    useEffect(() => {
        const toLoading = () => setForLoading(true);
        window.addEventListener('loading', toLoading);
        return () => window.removeEventListener('loading', toLoading);
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <GoogleOAuthProvider clientId={decryptedGoogleKey || ' '}>
                <FacebookProvider appId={decryptedFacebookKey || ' '} cookie={true} xfbml={true} version="v19.0">
                    <HelmetProvider>
                        <ContextProvider>
                            <Provider store={store}>
                                <RouterProvider router={Router} />
                                <UserAgent>
                                    {({ ua }) => {
                                        localStorage.setItem('UserAgent', ua.md.ua);
                                    }}
                                </UserAgent>
                                <FirstData />
                            </Provider>
                        </ContextProvider>
                    </HelmetProvider>
                </FacebookProvider>
            </GoogleOAuthProvider>
            <ToastContainer autoClose={2000} />
        </Suspense>
    );
};

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, <App />);
} else {
    createRoot(rootElement).render(<App />);
}
