import {createSlice} from "@reduxjs/toolkit";

import axiosClient from "../axios-client";

const CategoryProjects = createSlice({
    name:'StoreCategoryProjects',
    initialState:{
        data:[],
        pageData:''
    },
    reducers:{
        setProjects:(state,action)=>{
            state.data = action.payload
        },
        setPageData:(state,action)=>{
            state.pageData = action.payload
        }
    }
})

export const {setProjects,setPageData} = CategoryProjects.actions;

export const fetchProjects = (slug) => {
    return async (dispatch) => {
        try {
            const response = await axiosClient.get(`fixed/category-projects/${slug?.replace(/ /g, '-')}`);
            dispatch(setProjects(response.data.data.projects))
            dispatch(setPageData(response.data.data))
        } catch (error) {
            console.log(error);
        }
    };
};

export default CategoryProjects.reducer;