import React from 'react';
import {RangeSlider, defaultTheme, Provider} from '@adobe/react-spectrum'
import {translateText} from "./TranslateComponent";
import {useSelector} from "react-redux";

const RangePrice = ({
maxValue,
setValue,
value
}) => {
    const translations = useSelector((state) => state.Storetranslate.translations);

        return (
        <Provider theme={defaultTheme} colorScheme="light">
            <div className='!text-sm'>
            <RangeSlider
                label={translateText('range price',translations)}
                style={{width:"100%",marginTop:"50px"}}
                formatOptions={{style: 'currency', currency: 'USD'}}
                value={value}
                onChange={setValue}
                maxValue={maxValue}
            />
            </div>
        </Provider>

        );
};

export default RangePrice;
