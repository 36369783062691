import {createSlice} from "@reduxjs/toolkit";
import axiosClient, {getCurrencyLocal} from "../axios-client";
import {getPageTotal} from "./Projects";

const Campaigns = createSlice({
    name:'StoreCampaign',
    initialState:{
        data:[],
        pageTotal:'',
        currentPage:''
    },
    reducers:{
        setData:(state,action)=>{
            state.data=  [...state.data, ...action.payload];
        },
        setPageTotal:(state,action)=>{
            state.pageTotal= action.payload;
        },
        setCurrentPage:(state,action)=>{
            state.currentPage= action.payload;
        },
        cleaerCampaigns:(state,action)=>{
            state.data = action.payload;
        },
    }
})

export const {setData,setPageTotal,setCurrentPage,cleaerCampaigns}=Campaigns.actions;

export const fetchGetData =(page=1) => async (dispatch)=>{
    await axiosClient.get('campaigns', {
        params: {
            limit: 8,
            page: page,
            currency: getCurrencyLocal() && getCurrencyLocal().id
        }
    })
        .then(response => {
            dispatch(setPageTotal(response.data.data.pagination.total_pages));
            dispatch(setCurrentPage(response.data.data.pagination.current_page))
            dispatch(setData(response.data.data.data));
        })
        .catch(error => {
            console.error('Error:', error);
        });
}
export default Campaigns.reducer;