import {createSlice} from "@reduxjs/toolkit";
import axiosClient, {getCurrencyLocal} from "../../axios-client";
import {toast} from "react-toastify";
import { translateText } from "../TranslateComponent";


const Cart =createSlice({
    name:"StoreCart",
    initialState:{
        carts:[],
    },
    reducers:{
        getCarts :(state,action)=>{
            state.carts = action.payload;
        },
        getDataPending: (state) => {
            state.loading = true;
        },
    }
})
export const {getCarts,getDataPending} =Cart.actions;

export const fetchCreateCart = (type,cart_id,price,zaka_type=null,is_zakat=0)=>async (dispatch,state)=>{
    dispatch(getDataPending());
    const translations = state().Storetranslate.translations;
    try {
         await axiosClient.post('/cart/store',{
            cartable_type:type,
            cartable_id:cart_id,
            price:price,
            currency_id:getCurrencyLocal()&&getCurrencyLocal()?getCurrencyLocal().id:1,
            zaka_type:zaka_type,
            is_zakat:is_zakat
        });
        localStorage.setItem('haveCart',1);
        dispatch(fetchGetData());
        toast.success(translateText('success',translations))
        dispatchEvent(new Event('CreateCart'));
        return true
    }
    catch (error){
        if(error?.response?.data?.message){
            toast.error(translateText(error.response.data.message,translations))
        }
        console.log('error: '+error)
    }
}

export const fetchGetData =()=> async (dispatch)=>{
    dispatch(getDataPending());
    try {
        const response= await axiosClient.get('/cart')
        dispatch(getCarts(response.data.data))
        if(response.data.data.cart.length===0){
            localStorage.removeItem('haveCart');
            dispatchEvent(new Event('dontHaveCart'));
        }
    }
    catch (error){
        console.log('error: '+error)
    }
}
export const fetchDelteCartItem = (cart_id) => async(dispatch,state)=>{
    dispatch(getDataPending());
    const translations = state().Storetranslate.translations;

    try {
        const response= await axiosClient.post('/cart/delete',{
            cart_id:cart_id,
        });
        toast.success(translateText('success',translations))
        dispatch(fetchGetData());


    }
    catch (error){
        if(error?.response?.data?.message){
            toast.error(translateText(error.response.data.message,translations))
        }
        console.log('error: '+error)
    }
}
export const feachDeleteAllCarts = () =>async (dispatch) =>{
    dispatch(getDataPending());
    axiosClient.post('/cart/delete/all')
        .then(response=>{
            dispatch(fetchGetData());
        })
        .catch(error=>{
            console.log('ERROR: '+error)
        })
}
export default Cart.reducer;