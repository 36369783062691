import {createSlice} from "@reduxjs/toolkit";
import axiosClient, {updateUserHasCard} from "../axios-client";
import {toast} from "react-toastify";
import {translateText} from "../Components/TranslateComponent";

const PayCards = createSlice({
    name:"StorePayCards",
    initialState:{
        Cards:[],
        loader:false,
        RemoveLoader:false,
    },
    reducers:{
        setCard :(state,action)=>{
            state.Cards = action.payload
        },
        setLoader:(state,action) =>{
            state.loader = action.payload
        },
        setRemoveLoader:(state,action) =>{
            state.RemoveLoader = action.payload
        },
    }
})

export const {setCard,setLoader,setRemoveLoader}=PayCards.actions;

export const fetchCards = (dispatch)=>{
    dispatch(setLoader(true))

    axiosClient.get('cards')
        .then(res=>{
            dispatch(setCard(res.data.data));
            dispatch(setLoader(false))
        })
        .catch(error=>{
            console.log("ERROR",error);
            if(error?.response?.status === 404){
                dispatch(setCard([]));
            }
            dispatch(setLoader(false))
        })
}

export const handelRemoveCard = (id)=>(dispatch,state)=>{
    dispatch(setRemoveLoader(true));
    const translations = state().Storetranslate.translations;
    axiosClient.post('delete/card',{
        cardId:id
    })
        .then(res=>{
            dispatch(setRemoveLoader(false));
            if(res.status === 204 && !sessionStorage.getItem('AsAuth')){
                updateUserHasCard(null);
                dispatchEvent(new Event('loading'));
            }else{
                dispatch(fetchCards);
            }
        })
        .catch(error=>{
            if(error?.response?.data?.message){
                toast.error(translateText(error.response.data.message,translations))
            }
            console.log(error)
            dispatch(setRemoveLoader(false));
        })
}

export default PayCards.reducer;