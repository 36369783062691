import React, {useState} from 'react'
import 'react-multi-carousel/lib/styles.css';
import {HeartIcon} from '../../Images/svgs';
import axiosClient, {getLocalLang, getToken, truncateText} from "../../axios-client";
import {withToken} from "../../MiddelwareToken";
import {Fade} from "react-awesome-reveal";
import {useNavigate} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import { translateText } from '../TranslateComponent';
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';
import CustomLink from "../../CustomLink";

function FixedProjectCard({
    project,
    index  ,handelGetWish,
    className=null,
    hight=null,
    MobileMap=null,
    marginUnset=null,
 }) {
    const [wishList,setWishList]=useState(project.wishlist);
    const isTabletScreen = useMediaQuery({ query: '(min-width: 600px) and (max-width: 786px)' });


    const wishlist =(project_id,wishListStatus) =>{
        const url = wishListStatus?'delete':'create';
        const requestvariable = wishListStatus?"id":"project_id";
        axiosClient.post(`/wishlist/${url}`,{
            [requestvariable]: project_id
        }).then(response=>{
            if(url==='delete'){
                if(handelGetWish){
                    handelGetWish()
                }
                setWishList(false)
            }else{
                setWishList(true)
            }


        })
            .catch(error=>{
                console.log(error)
            })
    }
    const navigate = useNavigate();
    const handelAndCheckWishlist = withToken(wishlist)

    const goToLogin =()=>{
        navigate(`/${getLocalLang()?.code}/login`);
    }

    return (
        <>

            <Fade>
                <div key={index}
                     className={` ${className !== null ? className : 'my-3'}  sm:max-w-sm  max-w-sm h-full transform transition duration-300 hover:scale-105 border mx-auto border-gray-200 rounded-lg shadow bg-white  dark:border-gray-200 mk-rtl`}>
                    <div className={`p-1 space-y-4 ${hight ?? 'h-[inherit]'} flex flex-col justify-between `}>

                        <div key={index}
                             className={` ${MobileMap} flex overflow-hidden relative flex-col justify-between self-stretch px-3 pt-3 pb-12 text-sm font-medium tracking-normal leading-3 text-center text-emerald-900 uppercase whitespace-nowrap rounded-md aspect-[1.26]`}>
                            <LazyLoadImage
                                src={project.photo}
                                className={`${MobileMap} object-cover absolute inset-0 w-full size-full `}
                                alt={`Image ${index}`}
                            />
                            <div
                                className={`flex relative gap-2 ${marginUnset} justify-end sm:-top-1`}>

                                {getToken() ? (
                                    <button
                                        onClick={() => handelAndCheckWishlist(project.id, wishList)}>
                                        <HeartIcon fill={wishList ? 'red' : '#ffffff4a'}
                                                   className="w-6 aspect-square"/>
                                    </button>
                                ) : (
                                    <button onClick={() => {
                                        goToLogin()
                                    }}>
                                        <HeartIcon fill={'#ffffff4a'} className="w-6 aspect-square"/>
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className='px-2 flex flex-col  gap-4 flex-1 text-start'>
                            <h5 className="text-xl font-bold dark:text-black text-black ">
                                <CustomLink to={`/singleFixedProject/${project.slug}`}  onClick={()=>dispatchEvent(new Event('loadBlogPostPage'))}>
                                    {project?.name?.length && (
                                        isTabletScreen ? (
                                            truncateText(project.name, project.name.length > 10 ? 15 : project.name.length)
                                        ) : (
                                            truncateText(project.name, project.name.length > 35 ? 40 : project.name.length)
                                        )
                                    )}
                                </CustomLink>

                            </h5>
                            <div className={'flex gap-x-3 items-center justify-between'}>
                                <span
                                    className="font-normal  text-black dark:text-black mt-auto ">
                                    <div dangerouslySetInnerHTML={{__html: truncateText(project.description,150)}}/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>


        </>
    )
}

export default FixedProjectCard