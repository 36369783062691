import { useSelector } from 'react-redux';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {getLocalLang, savelangLocal} from "./axios-client";
import {useEffect, useState} from "react";

const LanguageWrapper = () => {
    const location = useLocation();
    const { pathname } = location;
    const {lang} = useParams()
    const language = useSelector(state => state.Storelang.langs);
    const langValues = language.map(language=>language.lang);
    const navigate = useNavigate();
    const langData = language.filter((item,index)=>item.lang === lang)[0];
    const [activeUseEffect , setActiveUseEffect]=useState(0);

    useEffect(()=>{
        if(activeUseEffect <3){
            if (language.length>0 && langValues.length>0 && !langValues.includes(lang)) {
                const langValue = getLocalLang()?.code ?getLocalLang()?.code : 'ar';
                navigate(`/${langValue}${pathname}`);
            }else{
                if(langData &&  getLocalLang()?.code!==lang){
                    savelangLocal({name:langData.name,code:langData.lang,icon:langData.icon});
                    window.location.reload();
                }
            }
            setActiveUseEffect((prevData)=>prevData+1);
        }
    },[language])

    return <Outlet />;

};
export default LanguageWrapper;
