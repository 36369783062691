import React, {useEffect, useRef, useState} from 'react';
import '../../css/custom.css';
import {AddIcon, DeleteIcon} from "../../Images/svgs";
import axiosClient, {getCurrencyLocal, Logout} from "../../axios-client";
import {useDispatch} from "react-redux";
import {fetchCreateCart} from "../Cart/Cart";
import TranslateComponent, { translateText } from '../TranslateComponent';
import { useSelector } from 'react-redux';

import  PopupComponent from '../PaymentSteps/PopupCopmponent';

const PopupForm = ({
requestType,
setRequestType,
type,
openPopUp,
closePopUp,
feed,
handleInputChange,
handleAddMoreInputs,
handleRemoveMoreInputs,
id,
adahy_price_id,
totalPrice
}) => {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const inputRefs = useRef([]);
    const [description, setDescription] = useState('');
    const dispatchCart = useDispatch();
    const [showPopupPay , setShowPopupPay] = useState(false);

    useEffect(()=>{
        if(requestType){
            if(type==='gift'){
                showPopupPay&&(  document.getElementById('my_modal_4_gift').showModal() )
            }else if(type==='adahy'){
                showPopupPay&&(  document.getElementById('my_modal_4_adahy').showModal() )
            }
            if(!showPopupPay){
                    closePopUp();
            }
        }
    },[showPopupPay])
    const handleLosePopUp = (e) => {
        if (e.target.id === 'ModelContainer') {
            closePopUp();
            setRequestType(false);
        }
    };
    const handleCloseModal = () => {
        closePopUp();
        setRequestType(false);

        if(sessionStorage.getItem('AsAuth')==='1'){
            closePopUp();
            setRequestType(false);
            sessionStorage.removeItem('AsAuth');

            dispatchEvent(new Event('removeForPayDirect'));
            Logout();
        }
    };
    const [loader,setLoader]=useState(false);
    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault();
        const AdahyData = {
            adahy_id: id,
            adahy_price_id: adahy_price_id,
            description: description,
            people: feed.map(item => ({
                name: item.name,
                email: item.value
            }))
        };
        const giftData = {
            gift_id: id,
            description: description,
            people: feed.map(item => ({
                name: item.name,
                email: item.value
            }))
        };
        try {

            if(type==='gift'){
                const response = await axiosClient.post('/donation/gift', giftData);
                if (response.data.data === true) {
                    if(requestType){
                        document.getElementById('GiftModelContainer').hidden = true;
                        setShowPopupPay(true);

                    }else{
                        const res = await dispatchCart(fetchCreateCart('gift', id, totalPrice));
                    }
                    setLoader(false)

                }
            }else if(type==='adahy'){
                const response = await axiosClient.post('/donation/adahy', AdahyData);
                if (response.data.data === true) {
                    if(requestType){
                        document.getElementById('GiftModelContainer').hidden = true;
                        setShowPopupPay(true);

                    }else{
                        const res = await dispatchCart(fetchCreateCart('adahy', id, totalPrice));
                    }
                    setLoader(false)
                }
            }

        } catch (error) {
            console.error('There was an error sending the data!', error);
        }
    };
    window.addEventListener('removeForPayDirect',()=>{
        setRequestType(false);
        closePopUp();
    })
    window.addEventListener('CreateCart',()=>{
        setRequestType(false);
        closePopUp();
    })
    if (!openPopUp) return null;
    return (
        <div
            id='ModelContainer'
            onClick={handleLosePopUp}
            className='fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm z-30'
        >
            <div id='GiftModelContainer' className='p-2 bg-white overflow-y-auto w-[316px] max-h-80 mt-20 md:w-[500px] md:max-h-[470px] lg:mt-48 shadow-inner border-e-emerald-600 rounded-lg py-5'>
                <div className='w-full  flex flex-col items-start '>
                    {/* Close button */}
                    <button onClick={handleCloseModal}
                            className=" text-gray-500 hover:text-gray-700 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>

                    <div className='font-semibold pb-3 text-center text-xl w-full'>
                        <form onSubmit={handleSubmit}>
                            {/* font size 14 might change later */}
                            <h4 className={'text-black my-3 text-sm'}>
                                {type==='adahy'&&(
                                    <TranslateComponent word={'participate'}/>
                                )}
                                {type==='gift'&&(
                                    <>
                                        <TranslateComponent word={'Please add the required information'}/>
                                        {' '}
                                        <TranslateComponent word={'Please_enter_the_information_of_the_person you_are_sending_to'}/>
                                    </>
                                )}
                            </h4>
                            
                            <div className="grid grid-cols-1 md:grid-cols-1  mt-4">
                                {feed.map((filteredItem, index) => (
                                    <div key={index}
                                         className='grid grid-cols-1 md:grid-cols-7 gap-2 mb-4'>
                                        <input
                                            ref={(element) => (inputRefs.current[index] = element)}
                                            className="col-span-1 md:col-span-3 w-full flex justify-center items-center px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 shadow-sm text-sm text-black"
                                            placeholder={translateText('name',translations)}
                                            value={filteredItem.name}
                                            required={true}
                                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                        />
                                        <input
                                            ref={(element) => (inputRefs.current[index + feed.length] = element)}
                                            className="col-span-1 md:col-span-3 w-full flex justify-center items-center px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 shadow-sm text-sm text-black"
                                            placeholder={translateText("E-mail",translations)}
                                            value={filteredItem.value}
                                            type="email"
                                            required={true}
                                            onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                                        />
                                        <button onClick={() => handleRemoveMoreInputs(index)}
                                                className="col-span-1 flex md:items-end md:justify-end">
                                            <DeleteIcon/>
                                        </button>
                                    </div>
                                ))}
                                <button onClick={handleAddMoreInputs}
                                        className="flex items-center justify-center mt-5">
                                    <AddIcon className="w-auto h-6 sm:h-8 aspect-square"/>
                                </button>
                            </div>
                            <textarea
                                name="description"
                                id="description"
                                className="form-control lh-7 dark:bg-white text-black scrollbar-none w-full p-2 border border-gray-300 rounded text-sm"
                                rows="3"
                                placeholder={translateText("Write description here",translations)}
                                value={description}
                                required={true}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                            {loader ? (
                                <button className="mt-5 px-4 py-2 rounded bg-blue-700 dark:bg-blue-700 text-white ">
                                    <span className="text-white loading loading-dots loading-lg"></span>
                                </button>
                            ) : (
                                <button type="submit"
                                        className="mt-5 px-4 py-2 rounded bg-blue-700 dark:bg-blue-700 text-white ">
                                    <TranslateComponent word={'send'}/>
                                </button>
                            )}

                        </form>
                    </div>
                </div>
            </div>
            {showPopupPay&&(
                <PopupComponent type={type} subscription={false} setShowPopup={setShowPopupPay} price={totalPrice} projectId={id}  symbol={getCurrencyLocal() && getCurrencyLocal().name}/>
            )}
        </div>
    );
};

export default PopupForm;
