import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const CACHE_KEY = 'countries_cache';
const CACHE_TIMESTAMP_KEY = 'countries_cache_timestamp';
const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000;
const Countries = createSlice({
    name: 'StoreCountries',
    initialState: {
        countries: []
    },
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload;
        }
    }
});

export const { setCountries } = Countries.actions;

export const fetchCountries = () => async (dispatch,getState) => {
    const now = Date.now();
    const cacheTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
    const cachedDataString = localStorage.getItem(CACHE_KEY);
    const cachedData = JSON.parse(cachedDataString)||[];
    const state = getState();
    if (cachedData.length>0 && cacheTimestamp && (now - cacheTimestamp < CACHE_DURATION)) {
        if(state.StoreCountries.countries && state.StoreCountries.countries.length < 1){
            console.log('Using cached Country data');
            dispatch(setCountries(cachedData));
        }
        return;
    }
    try {
        const response = await axiosClient.get("countries");
        const countries = response.data.data;
        localStorage.setItem(CACHE_KEY, JSON.stringify(countries));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, now.toString());
        dispatch(setCountries(countries));
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
};

export default Countries.reducer;
