import React from 'react';

function PriceDisplay({ price, currency }) {
    return (
        <div className="mt-6 w-full text-2xl font-medium leading-none text-slate-700">
            السعر : {price} {currency}
        </div>
    );
}

export default PriceDisplay;